import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

// style
import { WrapTransactionWalletItem } from './index.styles'

// image
import IcArrowRight from '../../../../../../../assets/images/IcArrowRight'
import IcFacePrintDefault from '../../../../../../../assets/images/IcFacePrintDefault'
import Logo_DefautBank from "../../../../../../../assets/images/Default_Bank.png";

// helpter
import { convertDateTime } from '../../../../../../../helper/convertDateTime'
import numberFormatToThousand from '../../../../../../../helper/numberFormatToThousand'

// component
import PopupWalletFacepay from '../PopupWalletFacepay'
import Portal from '../../../../../../Control/Portal'

// host
import { apiGetBankCustomerAvatar } from '../../../../../../../constant/host'

// data
import getValueWalletFacepay from '../../../../../../../data/dataWalletFacepay'
import { dataBankType, dataLogoBank } from '../../../../../../../data/dataBankType'
import { BANKCARD, FACEPAY, WALLET } from '../../../../../../../constant/keys'

const TransactionWalletItem = ({ item }) => {

    const [isShowPopupWalletFacepay, setIsShowPopupWalletFacepay] = useState(false)
    
    const [isErrImg, setIsErrImg] = useState(false)

    const { listBank } = useSelector((state) => ({
        listBank: state.transactionReducer.transactionOtherReducer.listBank,
    }))
    const logoMerchent = dataLogoBank(listBank)
    
    useEffect(() => {
        setIsErrImg(false)
    }, [item.thumbnailId])

    return (
        <>
            <WrapTransactionWalletItem onClick={() => setIsShowPopupWalletFacepay(true)}>
                <div className="wrap-item facepayTx">
                    {getValueWalletFacepay(item.transactionType.toUpperCase(), item.status).iconWallet}
                    <div className="item-text bold">#{item.facepayTrxCode || "-"}</div>
                </div>
                <div className="wrap-item">
                    {
                        isErrImg ? (
                            <IcFacePrintDefault className="face" />
                        ) : (
                            <img 
                                className="avatar" 
                                src={apiGetBankCustomerAvatar(item.thumbnailId)} 
                                alt="avatar"
                                onError={() => setIsErrImg(true)}
                            />
                        )
                    }
                    <div className="item-text" title={item.profileId}>{item.profileId || "-"}</div>
                </div>
                <div className="wrap-item">
                    <div className="item-text" title={item.userName}>{item.userName || "-"}</div>
                </div> 
                <div className="wrap-item">
                    <div className="item-icon">{item.from.supplier === FACEPAY ? dataBankType[item.from.supplier]?.icon : logoMerchent[item.from.supplier]?.icon || <img src={Logo_DefautBank} className="icon" />}</div>
                    <div className="item-text">
                        {
                            item.from.typeCard?.toUpperCase() === WALLET ? item.from.phone : 
                            item.from.typeCard?.toUpperCase() === BANKCARD ? item.from.cardNumber : 
                            item.from.accountNumber || "-"
                        }
                    </div>
                </div>
                <div className="wrap-item">
                    <div className="item-icon">{item.to.supplier === FACEPAY ? dataBankType[item.supplier]?.icon : logoMerchent[item.to.supplier]?.icon || <img src={Logo_DefautBank} className="icon" />}</div>
                    <div className="item-text">
                        {
                            item.to.typeCard?.toUpperCase() === WALLET ? item.to.phone : 
                            item.to.typeCard?.toUpperCase() === BANKCARD ? item.to.cardNumber : 
                            item.to.accountNumber || "-"
                        }
                    </div>
                </div>

                <div className="item-text bold">{numberFormatToThousand(item.amount) || 0}</div>
                <div className="item-text">{convertDateTime(item.createAt, "HH:mm - DD/MM/YYYY")}</div>
                <div className="wrap-item status">
                    {
                        item.status === 1 ? <div className='success'>Thành công</div> : <div className='failed'>Thất bại</div>
                    }
                    <IcArrowRight className="icon-arrow" />
                </div>

            </WrapTransactionWalletItem>
            {/* {
                isShowPopupWalletFacepay && (
                    <Portal>
                        <PopupWalletFacepay
                            onClickClosePopup={() => setIsShowPopupWalletFacepay(false)}
                            item={item}
                        />
                    </Portal>
                )
            } */}
        </>
    )
}

export default TransactionWalletItem
