import axios from 'axios';

import mapErrors from '../../../helper/mapErrors';

// types
import * as types from './types'

// host
import { hostCustomer} from '../../../constant/host'

// helper
import checkIsSuccessAction from '../../../helper/checkIsSuccessAction';
import checkStatusOnline from '../../../helper/checkStatusOnline';
import { CONNECTED, DISCONNECTED, TERMINATED, UNLINKED } from '../../../constant/keys';

const apiLuancherAction = `${hostCustomer}/launcher/action`
const apiGetAvatarMerchant = `${hostCustomer}/chain/avatar`

let headerDataRequest = {
    cid: "customer_web",
    reqId: `${new Date().getTime()}`,
    langCode: "vn",
}

const ContentType = 'image/png'
let lastQueryApiGetApiLogoMerchant = ""

let currentTime = Date.now()

export const getAvatarMerchant = (data = {id : ""}, callbackSuccess) => async dispatch => {
    headerDataRequest.reqId = `${new Date().getTime()}`
    lastQueryApiGetApiLogoMerchant = headerDataRequest.reqId

    const dataRequest = {...headerDataRequest, data}
    try {
        const res = await axios.post(apiGetAvatarMerchant, dataRequest ,{
            responseType: "blob"
        })
        const { data } = res
        if(lastQueryApiGetApiLogoMerchant !== dataRequest.reqId) return
        const fileRead = () => {
            const file = new File([data], 'blob', {type: ContentType})
            const fr = new FileReader()
            fr.readAsDataURL(file)
            fr.addEventListener('load', () => {
                dispatch({
                    type: types.GET_AVATAR_MERCHANT,
                    imageUrl: fr.result
                }) 
            })
        }

        fileRead()
        callbackSuccess && callbackSuccess()
    } catch (error) {
        dispatch(mapErrors(error, types.GET_AVATAR_MERCHANT_ERR))
    }
}

export const getListFirebaseDevices = (data, callbackSuccess) => async (dispatch) => {

    try {
        dispatch({
            type: types.GET_LIST_DEVICES_BY_ADMIN,
            payload: {
                list: data.map((item) => {
                    const checkStatus = checkStatusOnline(currentTime, item.time) === DISCONNECTED ? DISCONNECTED :
                        item.facepayApp.appStatus === TERMINATED ? TERMINATED : 
                        item.facepayApp.appStatus === UNLINKED ? UNLINKED : 
                        CONNECTED
                    return {
                        ...item,
                        status: checkStatus,
                        isSelect: false
                    }
                }) || [],
            }
        })

        callbackSuccess && callbackSuccess()
    }
    catch(err) {
        dispatch(mapErrors(err, types.GET_LIST_DEVICES_BY_ADMIN_ERR))
    }
}

export const getListTempFirebaseDevices = (data, callbackSuccess) => async (dispatch) => {

    try {
        dispatch({
            type: types.GET_LIST_DEVICES_BY_ADMIN_TEMP,
            payload: {
                listTemp: data.sort((a, b) => new Date(Number(a.time)) - new Date(Number(b.time))) || [],
            }
        })

        callbackSuccess && callbackSuccess()
    }
    catch(err) {
        dispatch(mapErrors(err, types.GET_LIST_DEVICES_BY_ADMIN_ERR))
    }
}

export const resetListDevicesAdmin = (callbackSuccess) => async (dispatch) => {
    dispatch({ type: types.RESET_LIST_DEVICES_BY_ADMIN });
    callbackSuccess && callbackSuccess();
  };
  
export const resetListDevicesByAdminId = (callbackSuccess) => async (dispatch) => {
    dispatch({ type: types.RESET_LIST_DEVICES_BY_ADMIN_LIST_TEMP });
    callbackSuccess && callbackSuccess();
};

export const toggleListViewMode = () => ({
    type: types.TOGGLE_LIST_VIEW_MODE,
})

export const resetListViewMode = () => ({
    type: types.RESET_LIST_VIEW_MODE,
})

export const updateDeviceCondsFilter = ({
    condFilters = {
        optionSelected: "",
        deviceId: "",
        status: "",
        deviceName: "",
        deviceVersion: ""
    },
    callbackSuccess
    }) => async (dispatch) => {

    dispatch({ 
        type: types.UPDATE_DEVICE_CONDFILTERS,
        condFilters,
    })
    callbackSuccess && callbackSuccess()
}

export const resetDeviceCondsFilter = () => async (dispatch) => {
    dispatch({ type: types.RESET_DEVICE_CONDFILTERS })
}

export const updateListIdCheckeds = (listIdCheckeds) => dispatch => {
    dispatch({
        type: types.UPDATE_LIST_ID_CHECKED_DEVICE,
        listIdCheckeds: listIdCheckeds
    })
}

export const resetListIdChecked = (callbackSuccess) => async (dispatch) => {
    dispatch({ type: types.RESET_LIST_ID_CHECKED_DEVICE })
    callbackSuccess && callbackSuccess()
}


export const detailsDevice = (data) => async dispatch => {
    dispatch({
        type: types.DETAIL_DEVICE,
        detailsDevice: data
    })
}

export const luancherAction = (
    data = {
        serialNumber : [],
        command : ""
    },
    callbackSuccess,
    callbackError
) => async dispatch => {
    headerDataRequest.reqId = `${new Date().getTime()}`

    const dataRequest = {...headerDataRequest, data}

    try {
        const res = await axios.post(apiLuancherAction, dataRequest)
        const { data } = res

        if(checkIsSuccessAction(data.code)){
            dispatch({
                type: types.LUANCHER_ACTION_SUCCESS,
                payload: {
                    data: data
                }
            })
            callbackSuccess && callbackSuccess()
        }else{
            dispatch({
                type: types.LUANCHER_ACTION_ERR,
                payload: {
                    data: data
                }
            })
            callbackError && callbackError()
        }
    } catch (err) {
        dispatch(mapErrors(err, types.LUANCHER_ACTION_ERR))
    }
}

export const isCheckcSreenshotDevice = () => dispatch => {
    dispatch({type: types.IS_CHECK_SCREENSHOT})
}

export const resetIsCheckcSreenshot = () => async (dispatch) => {
    dispatch({ type: types.RESET_IS_CHECK_SCREENSHOT })
}

export const isCheckcSnapshot = () => dispatch => {
    dispatch({type: types.IS_CHECK_SNAPSHOT})
}

export const resetIsCheckcSnapshot = () => async (dispatch) => {
    dispatch({ type: types.RESET_IS_CHECK_SNAPSHOT })
}