import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

//style
import { OverlayFullScreen } from '../../../../../../TransactionHistory/TransHistoryItem/PopupShowMedia/index.styles'
import { WrapCustomerTransHistoryDetailsItem } from './index.style'

//image
import IcCloseWhite from '../../../../../../../../../../assets/images/IcCloseWhite'
import IcCheckBgBlue from '../../../../../../../../../../assets/images/IcCheckBgBlue'
import IcCloseRedMini from '../../../../../../../../../../assets/images/IcCloseRedMini'
import IcRefundWhite from '../../../../../../../../../../assets/images/IcRefundWhite'
import IcPlayCircle from '../../../../../../../../../../assets/images/IcPlayCircle'
import IcTransVerifyVideo from '../../../../../../../../../../assets/images/IcTransVerifyVideo'
import IcZoomIn from '../../../../../../../../../../assets/images/IcZoomIn'
import IcTransPhotoDefault from '../../../../../../../../../../assets/images/IcTransPhotoDefault'
import Logo_DefautBank from "../../../../../../../../../../assets/images/Default_Bank.png";

//component
import Button from '../../../../../../../../../Control/Button'
import Portal from '../../../../../../../../../Control/Portal'
import PopupShowMedia, { IMAGE, VIDEO } from '../../../../../../TransactionHistory/TransHistoryItem/PopupShowMedia'

//help
import { convertDateTime } from '../../../../../../../../../../helper/convertDateTime'
import numberFormatToThousand from '../../../../../../../../../../helper/numberFormatToThousand'
import checkTimeTransactionComplete from '../../../../../../../../../../helper/checkTimeTransactionComplete'
import { SUCCESS } from '..'

//images
import IcNoteBlue from '../../../../../../../../../../assets/images/IcNoteBlue'
import IcAmountMini from '../../../../../../../../../../assets/images/IcAmountMini'
import IcTimeMini from '../../../../../../../../../../assets/images/IcTimeMini'
import IcBankMini from '../../../../../../../../../../assets/images/IcBankMini'
import IcStoreMini from '../../../../../../../../../../assets/images/IcStoreMini'
import IcEmailBgBlue from '../../../../../../../../../../assets/images/IcEmailBgBlue'
import IcPhoneBlueDefault from '../../../../../../../../../../assets/images/IcPhoneBlueDefault'
import IcUserBlueDefault from '../../../../../../../../../../assets/images/IcUserBlueDefault'
import IcFacePrintDefault from '../../../../../../../../../../assets/images/IcFacePrintDefault'
import IcMerchantLogoDefault from '../../../../../../../../../../assets/images/IcMerchantLogoDefault'
import IcGS25 from '../../../../../../../../../../assets/images/IcGS25'
import IcNotifyError from '../../../../../../../../../../assets/images/IcNotifyError'

import { dataBankType, dataLogoMerchant } from '../../../../../../../../../../data/dataBankType'
import { apiGetBankCustomerAvatar, apiGetBankCustomerTransactionVideo } from '../../../../../../../../../../constant/host'

//data errors bank
import { dataErrorTPB } from '../../../../../../../../../../dataErrorsBank/dataErrorTPB'
import { dataErrorOCB } from '../../../../../../../../../../dataErrorsBank/dataErrorOCB'
import { dataErrorVTB } from '../../../../../../../../../../dataErrorsBank/dataErrorVTB'
import { BIDV, PVCOMBANK, SMARTPAY, TPBANK, OCB, VIETTINBANK } from '../../../../../../../../../../constant/dataBank'
import { dataErrorSmartpay } from '../../../../../../../../../../dataErrorsBank/dataErrorSmartpay'
import { dataErrorFacepay } from '../../../../../../../../../../dataErrorsBank/dataErrorFacepay'
import { dataErrorPVC } from '../../../../../../../../../../dataErrorsBank/dataErrorPVC'
import { FACEPAY, POPUP_NOTE_TRANSACTION_CUSTOMER } from '../../../../../../../../../../constant/keys'
import PopupAttachNote from '../../../PopupAttachNote'
import { dataErrorsDeposit } from '../../../../../../../../../../dataErrorsDeposit/dataErrorsDeposit'


const CustomerTransDetail = ({ data, onClickClosePopup }) => {

    const {
        bioId,
        facepayTransCode,
        status,
        storeName,
        createdAt,
        transAmount,
        bankTransCode,
        merchantCode,
        merchantTransCode,
        supplier,
        swiftCode,
        thumbnailId,
        videoId,
        completeTime,
        paymentcode,
        paymentdescription,
        isWallet
    } = data;

    const [showMedia, setShowMedia] = useState(null);

    const [isErrImg, setIsErrImg] = useState(false)
    const [isErrCustomerPhoto, setIsErrCustomerPhoto] = useState(false);
    const [isErrCustomerVideo, setIsErrCustomerVideo] = useState(false);
    const [isShowPopupNote, setIsShowPopupNote] = useState(false)

    const { details, reqType } = useSelector((state) => ({
        details: state.customerReducer.customerDetailReducer.details,
        reqType: state.customerReducer.listCustomerReducer.reqType
    }))

    useEffect(() => {
        setIsErrImg(false)
    }, [details.thumbnailId])

    useEffect(() => {
        setIsErrCustomerPhoto(false)
    }, [thumbnailId])

    useEffect(() => {
        setIsErrCustomerVideo(false)
    }, [videoId])

    const _handleRefund = () => {

    }

    const _handleZoomPhoto = () => {
        if (isErrCustomerPhoto) return;
        setShowMedia({
            type: IMAGE,
            url: thumbnailId ? apiGetBankCustomerAvatar(thumbnailId) : "",

        });
    };

    const _handleZoomVideo = () => {
        if (isErrCustomerVideo) return;
        setShowMedia({
            type: VIDEO,
            url: videoId ? apiGetBankCustomerTransactionVideo(videoId) : "",
        });
    };

    return (
        <OverlayFullScreen>
            <div className="wrap-inner-screen">
                <WrapCustomerTransHistoryDetailsItem>
                    <IcCloseWhite className="icon-close" onClick={onClickClosePopup} />
                    <div className={status === 1 ? "wrap-status" : "wrap-status fail"}>
                        <div className="content">
                            {status === 1 ? <IcCheckBgBlue className="icon" /> : <IcCloseRedMini className="icon" />}
                            <p className="facepay-id">{facepayTransCode ? `#${facepayTransCode}` : "-"}</p>
                        </div>
                        <div className="content">
                            <div className="time">{createdAt ? convertDateTime(createdAt, "HH:mm:ss - DD/MM/YYYY") : "-"}</div>
                            {/* {
                                status === 1 &&
                                <Button
                                    text="Hoàn tiền"
                                    iconBtn={<IcRefundWhite />}
                                    className="btn-refund"
                                    onClick={_handleRefund}
                                />
                            } */}
                        </div>
                    </div>
                    {
                        status === 0 &&
                        <div className="wrap-reason">
                            <IcNotifyError className="error-icon" />
                            <div className="errro-text">
                                {supplier === TPBANK && (dataErrorTPB[paymentcode]?.text || "Giao dịch thất bại")}
                                {supplier === OCB && (dataErrorOCB[paymentcode]?.text || "Giao dịch thất bại")}
                                {supplier === VIETTINBANK && (dataErrorVTB[paymentcode]?.text || "Giao dịch thất bại")}
                                {supplier === SMARTPAY && (dataErrorSmartpay[paymentcode]?.text || "Giao dịch thất bại")}
                                {(reqType === FACEPAY && supplier === FACEPAY) && (dataErrorFacepay[paymentcode]?.text || "Giao dịch thất bại")}
                                {(reqType === FACEPAY && supplier === PVCOMBANK) && (dataErrorPVC[paymentcode]?.text || "Giao dịch thất bại")}
                                {/* update lỗi giao dịch ví facepay 18/11/2022 */}
                                {isWallet && ( dataErrorsDeposit[paymentcode]?.text || paymentdescription ) }
                            </div>
                        </div>
                    }
                    <div className="wrap-info-transaction">
                        <div className="info-transaction">
                            <div className="block-info">
                                <p className="title">Thông tin giao dịch</p>
                                <div className="wrap-info">
                                    <div className="info">
                                        <IcAmountMini className="icon" />
                                        <div className="content">
                                            <p className="value">{transAmount && numberFormatToThousand(transAmount)}</p>
                                            <p className="text">Số tiền (đ)</p>
                                        </div>
                                    </div>
                                    <div className="info">
                                        <IcTimeMini className="icon" />
                                        <div className="content">
                                            <p className="value">{checkTimeTransactionComplete(completeTime)}</p>
                                            <p className="text">TG hoàn tất giao dịch</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="block-info">
                                <p className="title">Phương thức thanh toán</p>
                                <div className="wrap-info">
                                    <div className="info">
                                        <IcBankMini className="icon" />
                                        <div className="content">
                                            <p className="value">{bankTransCode || "-"}</p>
                                            <p className="text">Bank Tx ID</p>
                                        </div>
                                    </div>
                                    <div className="info">
                                        <div className="icon">{reqType !== FACEPAY ? dataBankType[supplier]?.icon : dataBankType[swiftCode]?.icon || <img src={Logo_DefautBank} className="icon" /> }</div>
                                        <div className="content">
                                            <p className="value">{reqType !== FACEPAY ? dataBankType[supplier]?.text : dataBankType[swiftCode]?.text || "-"}</p>
                                            <p className="text">Tài khoản thanh toán</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="block-info">
                                <p className="title">Cửa hàng</p>
                                <div className="wrap-info">
                                    <div className="info">
                                        <IcStoreMini className="icon" />
                                        <div className="content">
                                            <p className="value">{merchantTransCode || "-"}</p>
                                            <p className="text">Merchant Tx ID</p>
                                        </div>
                                    </div>
                                    <div className="info">
                                        {
                                            merchantCode === "GS25" ?
                                                <div className="icon">{dataLogoMerchant[merchantCode].icon}</div>
                                                :
                                                <IcMerchantLogoDefault />

                                        }
                                        <div className="content">
                                            <p className="value">{storeName || "-"}</p>
                                            <p className="text">Cửa hàng</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="block-info-customer">
                                <p className="title">Khách hàng</p>
                                <div className="wrap-info-customer">
                                    <div className="info">
                                        {
                                            isErrImg ?
                                                <IcFacePrintDefault className="icon" />
                                                :
                                                <img
                                                    className="icon"
                                                    src={reqType === FACEPAY ? details?.thumbnailUrl : apiGetBankCustomerAvatar(details?.thumbnailId)}
                                                    alt="facePrint"
                                                    onError={() => setIsErrImg(true)}

                                                />
                                        }
                                        <div className="content">
                                            <p className="value">{details?.profileId || "-"}</p>
                                            <p className="text">Profile ID</p>
                                        </div>
                                    </div>
                                    <div className="info">
                                        <IcUserBlueDefault className="icon" />
                                        <div className="content">
                                            <p className="value">{details?.fullName || "-"}</p>
                                            <p className="text">Họ tên</p>
                                        </div>
                                    </div>
                                    <div className="info">
                                        <IcPhoneBlueDefault className="icon" />
                                        <div className="content">
                                            <p className="value">{details?.phone || "-"}</p>
                                            <p className="text">Số điện thoại</p>
                                        </div>
                                    </div>
                                    <div className="info">
                                        <IcEmailBgBlue className="icon" />
                                        <div className="content">
                                            <p className="value">{details?.email?.email || "-"}</p>
                                            <p className="text">Email đăng ký</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="info-face-registration">
                            <div className="wrap-value">
                                <div className="wrap-value-note">
                                    <IcNoteBlue className="ic-note" onClick={() => setIsShowPopupNote(true)} />
                                    <p className="note">Ghi chú</p>
                                </div>
                                {isShowPopupNote &&
                                <PopupAttachNote 
                                    isStylePopup={true}
                                    onClickClosePopup={() => setIsShowPopupNote(false)}
                                    data={data}
                                    isShowContentPopup={POPUP_NOTE_TRANSACTION_CUSTOMER}
                                />}
                            </div>
                            <div className="face-registration">
                                <div className="wrap-media face-img" onClick={_handleZoomPhoto}>
                                    {
                                        isErrCustomerPhoto ?
                                            <div className="wrapper">
                                                <IcTransPhotoDefault />
                                            </div>
                                            :
                                            <img
                                                src={thumbnailId ? apiGetBankCustomerAvatar(thumbnailId) : ""}
                                                alt="face registration"
                                                onError={() => setIsErrCustomerPhoto(true)}
                                            />
                                    }
                                    {/* <div className="tools">
                                        <IcZoomIn />
                                    </div> */}
                                </div>
                                <div className="wrap-media recog-video" onClick={_handleZoomVideo}>
                                    {
                                        isErrCustomerVideo ?
                                            <div className="wrapper">
                                                <IcTransVerifyVideo />
                                            </div>
                                            :
                                            <>
                                                <img
                                                    src={thumbnailId ? apiGetBankCustomerAvatar(thumbnailId) : ""}
                                                    alt="video registration"
                                                    onError={() => setIsErrCustomerVideo(true)}
                                                />
                                                <div className="overlay">
                                                    <IcPlayCircle />
                                                </div>
                                            </>
                                    }
                                </div>
                                {
                                    !!showMedia &&
                                    <Portal>
                                        <PopupShowMedia
                                            mediaType={showMedia.type}
                                            url={showMedia.url}
                                            closePopup={() => setShowMedia(null)}
                                        />
                                    </Portal>
                                }
                            </div>
                            <div className="wrap-value">
                                <p>Image Proof:</p>
                                <p className="value-bioId" title={bioId}>{bioId || "-"}</p>
                            </div>
                        </div>
                    </div>
                </WrapCustomerTransHistoryDetailsItem>
            </div>
        </OverlayFullScreen>
    )
}

export default CustomerTransDetail
