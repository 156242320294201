import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { WrapTransactionItem } from './index.styles'

// images
import IcCheckBgBlue from '../../../../../../../assets/images/IcCheckBgBlue'
import Logo_DefautBank from "../../../../../../../assets/images/Default_Bank.png";
import IcMerchantLogoDefault from '../../../../../../../assets/images/IcMerchantLogoDefault'
import IcCloseRedMini from '../../../../../../../assets/images/IcCloseRedMini'
import IcArrowRight from '../../../../../../../assets/images/IcArrowRight'

// contanst
import { apiGetBankCustomerAvatar, apiGetLogoMerchantFacepay } from '../../../../../../../constant/host'

// data
import { dataBankType, dataLogoBank, dataLogoMerchant } from '../../../../../../../data/dataBankType'
import numberFormatToThousand from '../../../../../../../helper/numberFormatToThousand'
import { convertDateTime } from '../../../../../../../helper/convertDateTime'
import TransHistoryDetailsItem from '../../../CustomerV2/CustomerInfoExpand/TransactionHistory/TransHistoryItem/TransHistoryDetailsItem'
import { TRANSACTION } from '../../../../../../../constant/path'
import Portal from '../../../../../../Control/Portal'
import IcRefundDone from '../../../../../../../assets/images/IcRefundDone';
import IcRefundPending from '../../../../../../../assets/images/IcRefundPending';
import IcFacePrintDefault from '../../../../../../../assets/images/IcFacePrintDefault';

// key
import { FACE_POS } from '../../../../../../../constant/keys';
import { FACEPAYTERMINAL } from '../..';

const TransactionItem = ({ item }) => {

    const [isShowPopupTrans, setIsShowPopupTrans] = useState(false)

    const { listBank, reqType } = useSelector((state) => ({
        listBank: state.transactionReducer.transactionOtherReducer.listBank,
        reqType: state.transactionReducer.transactionOtherReducer.reqType
    }))

    const [isErrImg, setIsErrImg] = useState(false)
    const [isErrImgLogoMerchant, setIsErrImgLogoMerchant] = useState(false)
    
    const logoMerchent = dataLogoBank(listBank)

    useEffect(() => {
        setIsErrImg(false)
    }, [item.thumbnailId])

    useEffect(() => {
        setIsErrImgLogoMerchant(false)
    }, [item.merchantCode])
    

    return (
        <>
            <WrapTransactionItem onClick={() => setIsShowPopupTrans(true)}>
                <div className="wrap-item facepayTx">
                    {item.status === 0 ? <IcCloseRedMini className="icon" /> : <IcCheckBgBlue className="icon" />}
                    <div className="item-text">{item.facepayTransCode || "-"}</div>
                </div>
                <div className="item-text" title={item.bankTransCode}>{item.bankTransCode || "-"}</div>
                <div className="item-text" title={item.merchantTransCode}>{item.merchantTransCode || "-"}</div>
                <div className="wrap-item">
                    {
                        isErrImg ? (
                            <IcFacePrintDefault className="avatar" />
                        ) : (
                            <img 
                                className="avatar" 
                                src={apiGetBankCustomerAvatar(item.thumbnailId)} 
                                alt="avatar"
                                onError={() => setIsErrImg(true)}
                            />
                        )
                    }
                    <div className="item-text" title={item.profileId}>{item.profileId || "-"}</div>
                </div>
                <div className="wrap-icon">
                    <div className="icon" title={dataLogoMerchant[item.merchantCode]?.text}>
                    {item.source === FACE_POS && item.businessID && !isErrImgLogoMerchant ? <img src={apiGetLogoMerchantFacepay(item.businessID)} alt="" onError={() => setIsErrImgLogoMerchant(true)}/> : dataLogoMerchant[item.merchantCode]?.icon || <IcMerchantLogoDefault />}
                    </div>
                </div>
                <div className="wrap-icon">
                    <div className="icon" title={dataBankType[item.supplier]?.text}>
                        {/* {dataBankType[item.supplier]?.icon || <img src={Logo_DefautBank} className="icon" />} */}
                        {reqType === FACEPAYTERMINAL ? dataBankType[item.supplier]?.icon : logoMerchent[item.supplier]?.icon || <img src={Logo_DefautBank} className="icon" />}
                    </div>
                </div>
                <div className="item-text bold">{numberFormatToThousand(item.transAmount) || 0}</div>
                <div className="item-text">{convertDateTime(item.createdAt, "HH:mm - DD/MM/YYYY")}</div>
                <div className="item-text">{item.codePromotion || ""}</div>
                <div className="wrap-item refund">
                    {item.refund === 1 ? <IcRefundDone /> : item.refund === 2 ? <IcRefundPending /> : ""}
                    <IcArrowRight className="icon-arrow" />
                </div>

            </WrapTransactionItem>
            {
                isShowPopupTrans && (
                    <Portal>
                        <TransHistoryDetailsItem
                            onClickClosePopup={() => setIsShowPopupTrans(false)}
                            dataTransacion={item}
                            detailsTrans={TRANSACTION}
                            logoMerchent={logoMerchent}
                            reqType={reqType}
                        />
                    </Portal>
                )
            }
        </>
    )
}

export default TransactionItem
