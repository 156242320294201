import styled from "styled-components";

const WrapFilterWaitingCensorship = styled.div`
    display: flex;
    align-items: center;
    gap: 18px;
    padding: 0 30px;

`

const FilterControlTime = styled.div`
    display: flex;
    align-items: center;
    border-radius: 33px;
    border: 0.5px solid #B7B7B7;
    box-sizing: border-box;
    padding: 9px 14px;
    width: 310px;
    height: 36px;
    .title{
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 15px;
        letter-spacing: -0.02em;
        margin: 0 8px;
    }
    .input-dropdown{
        width: 80px;
        button{
            width: 100%;
        }
    }
`

const FilterCheckBox = styled.div`
    display: flex;
    gap: 25px;
    .info-check{
        width: 110px;
        .text{
            width: 80px;
            font-weight: 400;
            font-size: 13px;
            line-height: 18px;
            letter-spacing: -0.01em;
            color: #313131;
            margin-left: 6px;
        }
        /* &.border-right{
            border-right: 0.5px solid #B7B7B7;
            padding-right: 18px;
        } */
        &.border-left{
            border-left: 0.5px solid #B7B7B7;
            padding-left: 18px;
        }
    }
`

export {
    WrapFilterWaitingCensorship,
    FilterControlTime,
    FilterCheckBox

}