import IcRefreshWaitingMini from "../assets/images/IcRefreshWaitingMini"
import IcUserRegisMini from "../assets/images/IcUserRegisMini"

export const headerWaitingCensorship = [
    "Document ID",
    "Faceprint",
    "Họ tên",
    "Số điện thoại",
    "Email",
    "Cảnh báo trùng khớp",
    "Thời gian chờ",
]

export const listNote = [
    {
        icon: <IcUserRegisMini />,
        text: "Đăng ký mới"
    },
    {
        icon: <IcRefreshWaitingMini />,
        text: "Chờ duyệt lại"
    },
]

export const listWaitingCensorship = [
    {
        id: "62737c1f49c1fe8245d0b8073",
        type: "NEW_REGISTRATION",
        documentId: "FA#27947",
        facePrint: "https://picsum.photos/36/36",
        fullName: "Hồ Trần Thị Cao Võ Văn",
        phoneNumber: "0909123456",
        email: "nga.nguyen@weedigital.vn",
        warning: [],
        waitTime: "2022-05-08T05:42:20.279Z"
    },
    {
        id: "62737c1f49c1fe8245d0dasd2",
        type: "WAITING_FOR_REVIEW",
        documentId: "FA#27948",
        facePrint: "https://picsum.photos/36/36",
        fullName: "Hồ Trần Thị Cao Võ Văn",
        phoneNumber: "0909123456",
        email: "nga.nguyen@weedigital.vn",
        warning: [1, 2],
        waitTime: "2022-05-09T03:42:20.279Z"
    },
    {
        id: "62737c1f49c1fe8245d0b8074",
        type: "NEW_REGISTRATION",
        documentId: "FA#27947",
        facePrint: "https://picsum.photos/36/36",
        fullName: "Hồ Trần Thị Cao Võ Văn",
        phoneNumber: "0909123456",
        email: "nga.nguyen@weedigital.vn",
        warning: [],
        waitTime: "2022-05-08T05:42:20.279Z"
    },
    {
        id: "62737c1f49c1fe8245d0dasd1",
        type: "WAITING_FOR_REVIEW",
        documentId: "FA#27948",
        facePrint: "https://picsum.photos/36/36",
        fullName: "Hồ Trần Thị Cao Võ Văn",
        phoneNumber: "0909123456",
        email: "nga.nguyen@weedigital.vn",
        warning: [1, 2],
        waitTime: "2022-05-09T03:42:20.279Z"
    },
    {
        id: "62737c1f49c1fe8245d0b8075",
        type: "NEW_REGISTRATION",
        documentId: "FA#27947",
        facePrint: "https://picsum.photos/36/36",
        fullName: "Hồ Trần Thị Cao Võ Văn",
        phoneNumber: "0909123456",
        email: "nga.nguyen@weedigital.vn",
        warning: [],
        waitTime: "2022-05-08T05:42:20.279Z"
    },
    {
        id: "62737c1f49c1fe8245d0dasd3",
        type: "WAITING_FOR_REVIEW",
        documentId: "FA#27948",
        facePrint: "https://picsum.photos/36/36",
        fullName: "Hồ Trần Thị Cao Võ Văn",
        phoneNumber: "0909123456",
        email: "nga.nguyen@weedigital.vn",
        warning: [1, 2],
        waitTime: "2022-05-09T03:42:20.279Z"
    },
    {
        id: "62737c1f49c1fe8245d0b8076",
        type: "NEW_REGISTRATION",
        documentId: "FA#27947",
        facePrint: "https://picsum.photos/36/36",
        fullName: "Hồ Trần Thị Cao Võ Văn",
        phoneNumber: "0909123456",
        email: "nga.nguyen@weedigital.vn",
        warning: [],
        waitTime: "2022-05-08T05:42:20.279Z"
    },
    {
        id: "62737c1f49c1fe8250dasd",
        type: "WAITING_FOR_REVIEW",
        documentId: "FA#27948",
        facePrint: "https://picsum.photos/36/36",
        fullName: "Hồ Trần Thị Cao Võ Văn",
        phoneNumber: "0909123456",
        email: "nga.nguyen@weedigital.vn",
        warning: [1, 2],
        waitTime: "2022-05-09T03:42:20.279Z"
    },
    {
        id: "62737c1f49c1fe845d0b807",
        type: "NEW_REGISTRATION",
        documentId: "FA#27947",
        facePrint: "https://picsum.photos/36/36",
        fullName: "Hồ Trần Thị Cao Võ Văn",
        phoneNumber: "0909123456",
        email: "nga.nguyen@weedigital.vn",
        warning: [],
        waitTime: "2022-05-08T05:42:20.279Z"
    },
    {
        id: "62737c1f49c1fe824d0dasd",
        type: "WAITING_FOR_REVIEW",
        documentId: "FA#27948",
        facePrint: "https://picsum.photos/36/36",
        fullName: "Hồ Trần Thị Cao Võ Văn",
        phoneNumber: "0909123456",
        email: "nga.nguyen@weedigital.vn",
        warning: [1, 2],
        waitTime: "2022-05-09T03:42:20.279Z"
    },
    {
        id: "62737c1f49c1fe8245d0807",
        type: "NEW_REGISTRATION",
        documentId: "FA#27947",
        facePrint: "https://picsum.photos/36/36",
        fullName: "Hồ Trần Thị Cao Võ Văn",
        phoneNumber: "0909123456",
        email: "nga.nguyen@weedigital.vn",
        warning: [],
        waitTime: "2022-05-08T05:42:20.279Z"
    },
    {
        id: "62737c1f49c1fe825d0dasd",
        type: "WAITING_FOR_REVIEW",
        documentId: "FA#27948",
        facePrint: "https://picsum.photos/36/36",
        fullName: "Hồ Trần Thị Cao Võ Văn",
        phoneNumber: "0909123456",
        email: "nga.nguyen@weedigital.vn",
        warning: [1, 2],
        waitTime: "2022-05-09T03:42:20.279Z"
    },
    {
        id: "62737c149c1fe8245d0b807",
        type: "NEW_REGISTRATION",
        documentId: "FA#27947",
        facePrint: "https://picsum.photos/36/36",
        fullName: "Hồ Trần Thị Cao Võ Văn",
        phoneNumber: "0909123456",
        email: "nga.nguyen@weedigital.vn",
        warning: [],
        waitTime: "2022-05-08T05:42:20.279Z"
    },
    {
        id: "6277c1f49c1fe8245d0dasd",
        type: "WAITING_FOR_REVIEW",
        documentId: "FA#27948",
        facePrint: "https://picsum.photos/36/36",
        fullName: "Hồ Trần Thị Cao Võ Văn",
        phoneNumber: "0909123456",
        email: "nga.nguyen@weedigital.vn",
        warning: [1, 2, 3],
        waitTime: "2022-05-09T03:42:20.279Z"
    },

]

export const detaisWaitingCensorship = {
    id: "62737c1f49c1fe8245d0b8077",
    type: "ACTIVE",
    documentId: "FA#27947",
    biometricId: "FP2342442",
    sendTime: "2021-07-16T03:42:20.279Z",
    reason: {
        text: "Hình ảnh không đủ sắc nét, bị nhiễu.",
        user: "user.name@facepay.vn",
        time: "2021-07-16T03:42:20.279Z"
    },
    detail: {
        fullName: "Trần Nguyên Khang",
        phoneNumber: "0909123456",
        email: "khang.tran@weedigital.vn"
    },
    face: {
        img: "https://picsum.photos/228/228",
        listMatch: [
            {
                avatar: "https://picsum.photos/36/36",
                fullName: "Đỗ Hoài Nam",
                phoneNumber: "0909123456"
            },
            {
                avatar: "https://picsum.photos/36/36",
                fullName: "Trần An Ninh",
                phoneNumber: "0909123456"
            },
            {
                avatar: "https://picsum.photos/36/36",
                fullName: "Đỗ Hoài Nam 3",
                phoneNumber: "0909123456"
            },
            {
                avatar: "https://picsum.photos/36/36",
                fullName: "Trần An Ninh 4",
                phoneNumber: "0909123456"
            },
            {
                avatar: "https://picsum.photos/36/36",
                fullName: "Đỗ Hoài Nam 5",
                phoneNumber: "0909123456"
            },
            // {
            //     avatar: "https://picsum.photos/36/36",
            //     fullName: "Trần An Ninh 6",
            //     phoneNumber: "0909123456"
            // }
        ]
    },
    listCensorshipHistory: [
        {
            type: "ACTIVE",
            documentId: "FA#27947",
            biometricId: "FP2342442",
            regTime: "2021-07-16T03:42:20.279Z",
            admin: "user.name@facepay.vn",
            approvedTime: "2021-07-16T03:42:20.279Z",
            reason: [
                {
                    key: 1,
                    text: "Hình ảnh không đủ sắc nét, bị nhiễu.",
                },
                {
                    key: 2,
                    text: "Hình ảnh khuôn mặt của người đăng ký không nằm giữa màn hình.",
                },
                {
                    key: 3,
                    text: "Hình ảnh chụp trong môi trường không đạt điều kiện ( thiếu sáng hoặc dư sáng).",
                },
                {
                    key: 4,
                    text: "Hình ảnh có phụ kiện che một phần khuôn mặt như kính răm, khẩu trang,...",
                },
                {
                    key: 5,
                    text: "Không phải là cùng một người trong ảnh kiểm duyệt trước đó.",
                },
                {
                    key: 6,
                    text: "Không phải là cùng một người trong ảnh kiểm duyệt trước đó.Không phải là cùng một người trong ảnh kiểm duyệt trước đó.Không phải là cùng một người trong ảnh kiểm duyệt trước đó.",
                },
            ],
            face: {
                img: "https://picsum.photos/228/228",
                listMatch: [
                    {
                        avatar: "https://picsum.photos/36/36",
                        fullName: "Đỗ Hoài Nam",
                        phoneNumber: "0909123456"
                    },
                    {
                        avatar: "https://picsum.photos/36/36",
                        fullName: "Trần An Ninh",
                        phoneNumber: "0909123456"
                    },
                ]
            }
        },
        {
            type: "REJECT",
            documentId: "FA#27948",
            biometricId: "FP2342113",
            regTime: "2021-07-16T03:42:20.279Z",
            admin: "user.name@facepay.vn",
            approvedTime: "2021-07-16T03:42:20.279Z",
            reason: [
                {
                    key: 1,
                    text: "Hình ảnh không đủ sắc nét, bị nhiễu.",
                },
                {
                    key: 2,
                    text: "Hình ảnh khuôn mặt của người đăng ký không nằm giữa màn hình.",
                },
                {
                    key: 3,
                    text: "Hình ảnh chụp trong môi trường không đạt điều kiện ( thiếu sáng hoặc dư sáng).",
                },
                {
                    key: 4,
                    text: "Hình ảnh có phụ kiện che một phần khuôn mặt như kính răm, khẩu trang,...",
                },
            ],
            face: {
                img: "https://picsum.photos/228/228",
                listMatch: [
                    {
                        avatar: "https://picsum.photos/36/36",
                        fullName: "Đỗ Hoài Nam",
                        phoneNumber: "0909123456"
                    },
                    {
                        avatar: "https://picsum.photos/36/36",
                        fullName: "Trần An Ninh",
                        phoneNumber: "0909123456"
                    },
                ]
            }
        }
    ],



}