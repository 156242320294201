import * as types from './types'

const initialState = {
    reasonRejectUser: [],
    reasonBlockCustomer: []
}

const listReasonReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_LIST_REASON:
            return {
                ...state,
                ...action.payload,
            }
        case types.CLEAR_LIST_REASON: 
            return {
                reasonRejectUser: [],
                reasonBlockCustomer: []
            }
        default:
            return state;
    }

}

export default listReasonReducer;