import styled from 'styled-components'

const WrapItem = styled.div`
    display: grid;
    grid-template-columns: minmax(120px, 150px) minmax(80px, 110px) minmax(180px, 1fr) minmax(113px, 1fr) minmax(230px, 1fr) minmax(140px, 1fr) 160px;
    grid-gap: 10px;    
    align-items: center;
    height: 60px;
    /* min-width: 1120px; */
    background: #FFFFFF;
    margin-bottom: 8px;
    box-shadow: inset 0px -0.55px 0px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13.5px;
    line-height: 18px;
    color: #313131;
    padding: 0 12px 0 16px;
    box-sizing: border-box;
    &:hover {
        cursor: pointer;
        border: 1px solid #33ADFF;
    }
    div{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
    }   
    .wrap-status{
        display: flex;
        align-items: center;
        .document-id{
            padding-left: 8px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
        }
    }
    .face-print{
        height: 36px;
        .face{
            width: 36px;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
        }
    }
    
    .wrap-time{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .icon-arrow{
            width: 14px;
            height: 14px;          
        }
    }
`
export { WrapItem }