import styled from "styled-components";
import { WrapTransHistoryDetailsItem } from "../index.styles";

const WrapCustomerTransHistoryDetailsItem = styled(WrapTransHistoryDetailsItem)`

    .wrap-info-space{
        margin-bottom: 20px;
    }
    .info-face-registration{
        .wrap-value-trans{
            display: flex;
            align-items: center;
            gap: 10px;
            justify-content: flex-end;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 11px;
            line-height: 15px;
            letter-spacing: -0.02em;
            color: #313131;
            position: relative;
            .wrap-value-note{
                display: flex;
                gap: 6px;
            }
        }
    }
    .info-icon{  
        width: 30px;
        height: 30px;      
        .icon {
            width: 100%;
            height: 100%;
            padding-left: 10px;
        }
        svg{
            width: 100%;
            height: 100%;
        }
        img{
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
    }
    .ic-note{
        cursor: pointer;
    }
`

export {
    WrapCustomerTransHistoryDetailsItem
}