const LOGIN = 'login'
const MAIN = 'main'
const TRANSACTION = 'transaction'
const HISTORY = 'history'
const ANALYTIC = 'analytic'
const MERCHANT = 'merchant'
const DEVICE = 'device'
const BANK = 'bank'
const PAYMENT_TERMINAL = "payment-terminal"
const SETTING = 'setting'
const CUSTOMER = 'customer'
const LOCATION = 'location'
const PERMISSION = "permission"
const CENSORSHIP = "censorship"
const WAITING_CENSORSHIP = "waiting-censorship"
const REJECTION_PROFILE = "rejection-profile"


export {
    LOGIN,
    MAIN,
    TRANSACTION,
    HISTORY,
    ANALYTIC,
    MERCHANT,
    DEVICE,
    BANK,
    PAYMENT_TERMINAL,
    SETTING,
    CUSTOMER,
    LOCATION, 
    PERMISSION,
    CENSORSHIP,
    WAITING_CENSORSHIP,
    REJECTION_PROFILE
}