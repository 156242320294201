

import { useDispatch } from "react-redux";
import { EXIT_APP, REBOOT, SCREENSHOT, SNAPSHOT, UPDATE_FACEPAY, UPDATE_LAUNCHER } from "../../../../../../constant/keys";
import { isCheckcSnapshot, isCheckcSreenshotDevice, luancherAction } from "../../../../../../redux/deviceV2/listDevicesByAdmin/action";

const HandleCallApiLuancherAction = (key, serialNumber) => {
  const dispatch = useDispatch()
  
  const handleLuancherAction = () => {
    switch (key) {
      case SCREENSHOT:
        return dispatch(
          luancherAction({
            serialNumber: serialNumber,
            command: key,
          }),
          dispatch(isCheckcSreenshotDevice())
        );
      case SNAPSHOT:
        return dispatch(
          luancherAction({
            serialNumber: serialNumber,
            command: key,
          }),
          dispatch(isCheckcSnapshot())
        );
      case REBOOT:
        return dispatch(
          luancherAction({
            serialNumber: serialNumber,
            command: key,
          })
        );
      case EXIT_APP:
        return dispatch(
          luancherAction({
            serialNumber: serialNumber,
            command: key,
          })
        );
      case UPDATE_FACEPAY:
        return dispatch(
          luancherAction({
            serialNumber: serialNumber,
            command: key,
          })
        );
      case UPDATE_LAUNCHER:
        return dispatch(
          luancherAction({
            serialNumber: serialNumber,
            command: key,
          })
        );
      default:
        return null;
    }
  }

return handleLuancherAction

};

export default HandleCallApiLuancherAction;
