import React from 'react'

const IcAmountMini = (props) => {
    return (
        <svg {...props} width={32} height={33} viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_6071_67027)">
                <circle cx={16} cy="16.5" r={16} fill="#EFF9FF" />
                <path d="M10.7109 16.5H21.2887" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M16 11.2109L21.2889 16.4998L16 21.7887" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_6071_67027">
                    <rect width={32} height={32} fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IcAmountMini
