import React from "react";
import { useSelector } from "react-redux";
import { BANK_TX_ID, FACEPAY_TX_ID, MERCHANT_TX_ID, PHONE_NUMBER, PROFILE_ID, VOUCHER } from "../../../../../../../../constant/keys";
import removeAccent from "../../../../../../../../helper/removeAccent";
import trimStartAndStripStr from "../../../../../../../../helper/trimStartAndStripStr";
import { InputFilter } from "../../../../../../../Control/FilterControl/index.styles";

const InputSearchTransaction = ({
  filterCondTransaction,
  valueSearch,
  setValueSearch,
}) => {

  const { text , key } = filterCondTransaction

  const showContentInputSearch = () => {
    switch (key) {
      case FACEPAY_TX_ID:
        return (
          <InputFilter
            value={valueSearch}
            type="text"
            placeholder={`Nhập ${text}...`}
            onChange={(e) =>
              setValueSearch(trimStartAndStripStr(removeAccent(e.target.value)))
            }
            maxLength={20}
          />
        );
      case BANK_TX_ID:
        return (
          <InputFilter
            value={valueSearch}
            type="text"
            placeholder={`Nhập ${text}...`}
            onChange={(e) =>
              setValueSearch(trimStartAndStripStr(removeAccent(e.target.value)))
            }
            maxLength={20}
          />
        );
      case MERCHANT_TX_ID:
        return (
          <InputFilter
            value={valueSearch}
            type="text"
            placeholder={`Nhập ${text}...`}
            onChange={(e) =>
              setValueSearch(trimStartAndStripStr(removeAccent(e.target.value)))
            }
            maxLength={20}
          />
        );
      case PROFILE_ID:
        return (
          <InputFilter
            value={valueSearch}
            type="text"
            placeholder={`Nhập ${text}...`}
            onChange={(e) =>
              setValueSearch(trimStartAndStripStr(removeAccent(e.target.value)))
            }
            maxLength={20}
          />
        );
      case PHONE_NUMBER:
        return (
          <InputFilter
            value={valueSearch}
            type="text"
            placeholder={`Nhập ${text}...`}
            onChange={(e) =>
              setValueSearch(trimStartAndStripStr(removeAccent(e.target.value)))
            }
            maxLength={10}
          />
        );

      case VOUCHER:
        return (
          <InputFilter
            value={valueSearch}
            type="text"
            placeholder={`Nhập ${text}...`}
            onChange={(e) =>
              setValueSearch(trimStartAndStripStr(removeAccent(e.target.value)))
            }
            maxLength={20}
          />
        );

      default:
        return null;
    }
  };

  return <>{showContentInputSearch()}</>;
};

export default InputSearchTransaction;
