import React, { useEffect, useReducer, useRef, useState } from 'react'

//styles
import { WrapPopupPopupChangePass } from './index.styles'

//component
import IcEditWhite from '../../../../assets/images/IcEditWhite'
import IcClose from '../../../../assets/images/IcClose'
import IcUserDefault from '../../../../assets/images/IcUserDefault'
import InputEmail from './InputEmail'
import InputNewPassword from './InputNewPassword'
import InputOldPassword from './InputOldPassword'
import InputUserName from './InputUserName'
import Button from '../../../Control/Button'

//hook
import { useOnClickOutside } from '../../../../hook/useClickOutside'
import { OverlayFullScreen } from '../../Content/Page/TransactionHistory/TransHistoryItem/PopupShowMedia/index.styles'

const PopupChangePass = ({ setIsShowPopupChangePass, userName }) => {

    const refChangePassword = useRef(null)
    const [stateForm, setStateForm] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            fullName: userName,
            emailAddress: "vtb-account01@gmail.com",
            oldPassword: "",
            newPassword: "",

        }
    )

    const _handleChange = (stateKey) => (e) => {
        setStateForm({ [stateKey]: e.target.value })
    }

    // useEffect(() => {
    // }, [stateForm])

    const _handleValidateInputOldPw = (value, callback) =>{
        const { oldPassword, newPassword } = stateForm
        if(!value) return;
        if(newPassword){
            if(value !== newPassword){
                callback("Password does not match")
            }
        }
    }

    const _handleValidateInputNewPw = (value, callback) =>{
        const { oldPassword, newPassword } = stateForm
        if(!value) return;
        if(value !== oldPassword){
            callback("Password does not match")
        }
    }


    const _handleSubmit = () =>{
        const { fullName, emailAddress, oldPassword, newPassword } = stateForm
        const dataRequest = {
            fullName,
            emailAddress,
            oldPassword,
            newPassword
        }
        if(oldPassword || newPassword){
            if(oldPassword !== newPassword) return;
            if(oldPassword === newPassword){
                // console.log("dispatch: ", dataRequest)
                setIsShowPopupChangePass(false)
            }
        }else{
            // console.log("dispatch: ",dataRequest)
            setIsShowPopupChangePass(false)
        }
    }


    useOnClickOutside(refChangePassword, () => setIsShowPopupChangePass(false))

    return (
        <OverlayFullScreen>
            <div className="wrap-inner-screen">
                <WrapPopupPopupChangePass ref={refChangePassword}>
                    <IcClose className="icon-close" onClick={() => setIsShowPopupChangePass(false)} />
                    <div className="title">
                        <div className="icon-title">
                            <IcUserDefault />
                        </div>
                        <div className="right-title">
                            <p className="title-bold">Configured Account Profile</p>
                            <p className="title-normal">Setting your profile</p>
                        </div>
                    </div>
                    <InputUserName
                        valueInput={stateForm.fullName}
                        onChange={_handleChange("fullName")}
                        placeholder="Fullname"
                        className="inputFullName"
                    />
                    <InputEmail
                        valueInput={stateForm.emailAddress}
                        onChange={_handleChange("emailAddress")}
                        placeholder="Email Address"
                        className="inputChangePass"
                        disabled="disabled"
                    />
                    <InputOldPassword
                        valueInput={stateForm.oldPassword}
                        onChange={_handleChange("oldPassword")}
                        placeholder="Old Password"
                        className="inputChangePass"
                        handleValidationUser={_handleValidateInputOldPw}
                    />
                    <InputNewPassword
                        valueInput={stateForm.newPassword}
                        onChange={_handleChange("newPassword")}
                        placeholder="New Password"
                        className="inputChangePass"
                        handleValidationUser={_handleValidateInputNewPw}
                    />
                    <Button
                        text="Save Profile"
                        iconBtn={<IcEditWhite />}
                        className="btn-changePass"
                        onClick={_handleSubmit}
                    />
                </WrapPopupPopupChangePass>
            </div>
        </OverlayFullScreen>
    )
}

export default PopupChangePass
