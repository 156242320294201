import styled from "styled-components";

const WrapPopupExportFileV2 = styled.div`
    width: 324px;
    padding: 20px;
    position: absolute;
    top: 20px;
    right: 0;
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    z-index: 1;
    .popup-content{
        display: flex;
        flex-direction: column;
        .icon-close{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            cursor: pointer;
        }
        .content-title{
            font-family: 'Open Sans';
            font-style: normal;
            margin-bottom: 22px;
            .title{
                font-weight: 600;
                font-size: 15px;
                line-height: 20px;
                letter-spacing: -0.01em;
                color: #313131;
            }
            .value{
                font-weight: 400;
                font-size: 11px;
                line-height: 15px;
                letter-spacing: -0.02em;
                color: #313131;
            }
        }
        .content-date-time{
            width: 320px;
            height: 36px;
            display: flex;
            align-items: center;
            border-radius: 33px;
            border: 0.5px solid #B7B7B7;
            box-sizing: border-box;
            padding: 9px 14px;
            margin-bottom: 40px;
            .date-title{
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 11px;
                line-height: 15px;
                letter-spacing: -0.02em;
                color: #313131;
                margin: 0 12px 0 6px;
            }
        }
        .content-checkbox{
            display: grid;
            gap: 12px;
            grid-template-columns: repeat(2, 160px);
            .info-check{
                gap: 6px;
            }
        }
        .content-bottom{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 40px;
            .bottom-left{
                background: none;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 13.5px;
                line-height: 18px;
                letter-spacing: -0.01em;
                color: #33ADFF;
                padding: 0;
            }
        }
    }
    
`

export {
    WrapPopupExportFileV2
}