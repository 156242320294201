import * as types from './types'
import Axios from 'axios';

// constant
import { hostCustomer } from '../../../constant/host'

// helper
import checkIsSuccessAction from '../../../helper/checkIsSuccessAction'
import mapErrors from '../../../helper/mapErrors';

// action
import { hideLoading, showLoading } from '../../loading/action';

//FACEPAY
const apiGetListCustomerNote = `${hostCustomer}/customer/getusernotes`
const apiCustomerMakeNote = `${hostCustomer}/customer/makenote`

//BANK
const apiGetListCustomerBankNote = `${hostCustomer}/customer/bank/getusernotes`
const apiCustomerMakeBankNote = `${hostCustomer}/customer/bank/makenote`

let headerDataRequest = {
    cid: "customer_web",
    reqId: `${new Date().getTime()}`,
    langCode: "vn",
};

let isCalling = false

// FACEPAY ACTION
export const getListCustomerNote = (
    data = {
        adminId: "",
        adminUserName: "",
        uid: "",
        limit: 15,
        skip: 0
    },
    isScroll = false,
    callbackSuccess,
    callbackError
) => async dispatch => {
    headerDataRequest.reqId = `${new Date().getTime()}`
    const dataRequest = {...headerDataRequest, data}

    dispatch(showLoading(types.LOADING_GET_LIST_CUSTOMER_NOTE))
    if(isCalling) return
    isCalling = true
    try {
        const res = await Axios.post(apiGetListCustomerNote, dataRequest)
        const { data } = res

        if (checkIsSuccessAction) {
            dispatch({
                type: types.GET_LIST_CUSTOMER_NOTE,
                payload: {
                    listNote: data.data.list || [],
                    isScroll
                }
            })
            callbackSuccess && callbackSuccess() 
            isCalling = false
        }else {
            dispatch({
                type: types.GET_LIST_CUSTOMER_NOTE_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.GET_LIST_CUSTOMER_NOTE_ERR))
    }

    setTimeout(() => {
        dispatch(hideLoading(types.LOADING_GET_LIST_CUSTOMER_NOTE))
    }, 300);
    
    isCalling = false
}

export const resetListCustomerNote = (callbackSuccess) => async dispatch => {
    dispatch({type: types.RESET_LIST_CUSTOMER_NOTE})
    callbackSuccess && callbackSuccess()
}

export const customerMakeNotePayment = (
    data = {
        adminId: "",
        adminUserName: "",
        uid: "",
        noteType: "",
        message: "",
        noteAttachments: {
            state: "",
            fbTransactionCode: "",
            amount: 0,
            createAt: ""
        }
    },callbackSuccess, callbackError
) => async dispatch => {
    headerDataRequest.reqId = `${new Date().getTime()}`
    const dataRequest = {...headerDataRequest, data}

    try {
        const res = await Axios.post(apiCustomerMakeNote, dataRequest)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.CUSTOMER_MAKE_NOTE_PAYMENT,
                payload: {
                    ...data
                }
            })
            callbackSuccess && callbackSuccess()
        } else {
            dispatch({
                type: types.CUSTOMER_MAKE_NOTE_PAYMENT_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    } catch (error) {
        mapErrors(error, types.CUSTOMER_MAKE_NOTE_PAYMENT_ERR)
    }
}

export const customerMakeNoteBacklog = (
    data = {
        adminId: "",
        adminUserName: "",
        uid: "",
        noteType: "",
        message: "",
        noteAttachments: {
            type: "",
            info: [],
            createAt: ""
        }
    },callbackSuccess, callbackError
) => async dispatch => {
    headerDataRequest.reqId = `${new Date().getTime()}`
    const dataRequest = {...headerDataRequest, data}

    try {
        const res = await Axios.post(apiCustomerMakeNote, dataRequest)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.CUSTOMER_MAKE_NOTE_BACKLOG,
                payload: {
                    ...data
                }
            })
            callbackSuccess && callbackSuccess()
        } else {
            dispatch({
                type: types.CUSTOMER_MAKE_NOTE_BACKLOG_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    } catch (error) {
        mapErrors(error, types.CUSTOMER_MAKE_NOTE_BACKLOG_ERR)
    }
}

export const customerMakeNoteCS = (
    data = {
        adminId: "",
        adminUserName: "",
        uid: "",
        noteType: "",
        message: "",
    },callbackSuccess, callbackError
) => async dispatch => {
    headerDataRequest.reqId = `${new Date().getTime()}`
    const dataRequest = {...headerDataRequest, data}

    try {
        const res = await Axios.post(apiCustomerMakeNote, dataRequest)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.CUSTOMER_MAKE_NOTE_CS,
                payload: {
                    ...data
                }
            })
            callbackSuccess && callbackSuccess()
        } else {
            dispatch({
                type: types.CUSTOMER_MAKE_NOTE_CS_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    } catch (error) {
        mapErrors(error, types.CUSTOMER_MAKE_NOTE_CS_ERR)
    }
}

// BANK ACTION
export const getListCustomerBankNote = (
    data = {
        adminId: "",
        adminUserName: "",
        bioId: "",
        limit: 15,
        skip: 0
    },
    isScroll = false,
    callbackSuccess,
    callbackError
) => async dispatch => {
    headerDataRequest.reqId = `${new Date().getTime()}`
    const dataRequest = {...headerDataRequest, data}

    dispatch(showLoading(types.LOADING_GET_LIST_CUSTOMER_NOTE))
    if(isCalling) return
    isCalling = true
    try {
        const res = await Axios.post(apiGetListCustomerBankNote, dataRequest)
        const { data } = res

        if (checkIsSuccessAction) {
            dispatch({
                type: types.GET_LIST_CUSTOMER_BANK_NOTE,
                payload: {
                    listNote: data.data.list || [],
                    isScroll
                }
            })
            callbackSuccess && callbackSuccess() 
            isCalling = false
        }else {
            dispatch({
                type: types.GET_LIST_CUSTOMER_NOTE_BANK_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.GET_LIST_CUSTOMER_NOTE_BANK_ERR))
    }

    setTimeout(() => {
        dispatch(hideLoading(types.LOADING_GET_LIST_CUSTOMER_NOTE))
    }, 300);
    
    isCalling = false
}

export const resetListCustomerBankNote = (callbackSuccess) => async dispatch => {
    dispatch({type: types.RESET_LIST_CUSTOMER_BANK_NOTE})
    callbackSuccess && callbackSuccess()
}

export const customerMakeNoteBankPayment = (
    data = {
        adminId: "",
        adminUserName: "",
        bioId: "",
        noteType: "",
        message: "",
        noteAttachments: {
            state: "",
            fbTransactionCode: "",
            amount: 0,
            createAt: ""
        }
    },callbackSuccess, callbackError
) => async dispatch => {
    headerDataRequest.reqId = `${new Date().getTime()}`
    const dataRequest = {...headerDataRequest, data}

    try {
        const res = await Axios.post(apiCustomerMakeBankNote, dataRequest)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.CUSTOMER_MAKE_NOTE_BANK_PAYMENT,
                payload: {
                    ...data
                }
            })
            callbackSuccess && callbackSuccess()
        } else {
            dispatch({
                type: types.CUSTOMER_MAKE_NOTE_BANK_PAYMENT_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    } catch (error) {
        mapErrors(error, types.CUSTOMER_MAKE_NOTE_BANK_PAYMENT_ERR)
    }
}

export const customerMakeNoteBankBacklog = (
    data = {
        adminId: "",
        adminUserName: "",
        bioId: "",
        noteType: "",
        message: "",
        noteAttachments: {
            type: "",
            info: [],
            createAt: "",
            idBacklog: ""
        }
    },callbackSuccess, callbackError
) => async dispatch => {
    headerDataRequest.reqId = `${new Date().getTime()}`
    const dataRequest = {...headerDataRequest, data}

    try {
        const res = await Axios.post(apiCustomerMakeBankNote, dataRequest)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.CUSTOMER_MAKE_NOTE_BANK_BACKLOG,
                payload: {
                    ...data
                }
            })
            callbackSuccess && callbackSuccess()
        } else {
            dispatch({
                type: types.CUSTOMER_MAKE_NOTE_BANK_BACKLOG_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    } catch (error) {
        mapErrors(error, types.CUSTOMER_MAKE_NOTE_BANK_BACKLOG_ERR)
    }
}

export const customerMakeNoteBankCS = (
    data = {
        adminId: "",
        adminUserName: "",
        bioId: "",
        noteType: "",
        message: "",
    },callbackSuccess, callbackError
) => async dispatch => {
    headerDataRequest.reqId = `${new Date().getTime()}`
    const dataRequest = {...headerDataRequest, data}

    try {
        const res = await Axios.post(apiCustomerMakeBankNote, dataRequest)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.CUSTOMER_MAKE_NOTE_BANK_CS,
                payload: {
                    ...data
                }
            })
            callbackSuccess && callbackSuccess()
        } else {
            dispatch({
                type: types.CUSTOMER_MAKE_NOTE_BANK_CS_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    } catch (error) {
        mapErrors(error, types.CUSTOMER_MAKE_NOTE_BANK_CS_ERR)
    }
}


// SHOW-HIDE POPUP NOTE
export const showPopupNote = () => async dispatch => {
    dispatch({type: types.SHOW_POPUP_NOTE})
}

export const hidePopupNote = () => async dispatch => {
    if(isCalling) return
    isCalling = true
    dispatch({type: types.HIDE_POPUP_NOTE})
    isCalling = false
}

