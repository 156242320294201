import React from "react";
import { useDispatch, useSelector } from "react-redux";

// style
import { WrapPopupOpenLocked } from "./index.style";
import { OverlayFullScreen } from "../../../../TransactionHistory/TransHistoryItem/PopupShowMedia/index.styles";

//component
import Button from "../../../../../../../Control/Button";

//image
import IcUnlockWhite from "../../../../../../../../assets/images/IcUnlockWhite";
import { closedPopupOpenLockDetailCustomer, unLockCustomer, unLockCustomerBank } from "../../../../../../../../redux/customer/customerDetail/action";
import { getListCustomerBank, getListCustomerFacepay } from "../../../../../../../../redux/customer/listCustomer/actionV2";
import { getListCustomerBankBacklog, getListCustomerFacepayBacklog, resetListCustomerBankBacklog, resetListCustomerFacepayBacklog } from "../../../../../../../../redux/customer/customerBacklog/action";

// constant
import { BANK, FACEPAY } from "../../../../../../../../constant/keys";

const PopupOpenLocked = ({ fullName, profileId }) => {
  const dispatch = useDispatch();

  const { condFiltersFacepay , listCustomerFacepay, condFiltersBank, condFiltersBacklog, listCustomerBank, reqType} = useSelector((state) => ({
    // FACEPAY
    condFiltersFacepay: state.customerReducer.listCustomerReducer.condFiltersFacepay,
    listCustomerFacepay: state.customerReducer.listCustomerReducer.listCustomerFacepay,

    // BANK
    condFiltersBank: state.customerReducer.listCustomerReducer.condFiltersBank,
    listCustomerBank: state.customerReducer.listCustomerReducer.listCustomerBank,

    // BACKLOG
    condFiltersBacklog: state.customerReducer.customerBacklogReducer.condFilters,

    // TYPE
    reqType: state.customerReducer.listCustomerReducer.reqType,

  }))
  const callbackSuccess = () => {
    switch (reqType) {
      case FACEPAY:
        dispatch(getListCustomerFacepay({
          ...condFiltersFacepay,
          lastItem: '',
          limit: listCustomerFacepay.length === 0 ? 15 : listCustomerFacepay.length,
        }))
        const callbackSuccessListBacklogFacepay = () => {
          if(condFiltersBacklog.uid){
            dispatch(getListCustomerFacepayBacklog({
              adminId: condFiltersBacklog.adminId,
              adminUserName: condFiltersBacklog.adminUserName,
              uid: condFiltersBacklog.uid,
              type: condFiltersBacklog.type.key,
              skip: condFiltersBacklog.skip,
              limit: condFiltersBacklog.limit,
              begin: condFiltersBacklog.begin,
              end: condFiltersBacklog.end
            }))
          }
        }
        dispatch(resetListCustomerFacepayBacklog(callbackSuccessListBacklogFacepay))
        break;
        
      case BANK:
        dispatch(getListCustomerBank({
          ...condFiltersBank,
          skipItem: 0,
          limit: listCustomerBank.length === 0 ? 15 : listCustomerBank.length,
        }))
        const callbackSuccessListBacklogBank = () => {
          if(condFiltersBacklog.bioId){
            dispatch(getListCustomerBankBacklog({
              adminId: condFiltersBacklog.adminId,
              adminUserName: condFiltersBacklog.adminUserName,
              bioId: condFiltersBacklog.bioId,
              type: condFiltersBacklog.type.key,
              skip: condFiltersBacklog.skip,
              limit: condFiltersBacklog.limit,
              begin: condFiltersBacklog.begin,
              end: condFiltersBacklog.end
            }))
          }
        }
        dispatch(resetListCustomerBankBacklog(callbackSuccessListBacklogBank))
        break;
    }
  };

  const handleSubmit = () => {
    if(reqType === FACEPAY){
      dispatch(unLockCustomer({uid: profileId}, callbackSuccess));
    }else{
      dispatch(unLockCustomerBank({bioId: profileId}, callbackSuccess))
    }

    dispatch(closedPopupOpenLockDetailCustomer())
  };

  return (
    <OverlayFullScreen>
      <WrapPopupOpenLocked>
        <div className="popup-title">Mở khoá tài khoản</div>
        <div className="popup-value">
          Bạn vui lòng xác nhận mở khoá tài khoản: <span>{fullName}</span>
        </div>
        <div className="popup-btn">
          <Button
            text="Mở khoá"
            iconBtn={<IcUnlockWhite />}
            className="btn-confirm"
            onClick={handleSubmit}
          />
          <Button
            text="Xem lại"
            className="btn-return"
            onClick={() => dispatch(closedPopupOpenLockDetailCustomer())}
          />
        </div>
      </WrapPopupOpenLocked>
    </OverlayFullScreen>
  );
};

export default PopupOpenLocked;
