import React from "react";

//style
import {WrapTextArea} from './index.styles'

const TextArea = ({ placeholder, maxLength, onChange, value, onKeyPress, ...props }) => {
  return (
    <WrapTextArea>
      <textarea
        placeholder={placeholder}
        maxLength={maxLength}
        onChange={onChange}
        value={value}
        onKeyPress={onKeyPress}
      />
    </WrapTextArea>
  );
}

export default TextArea