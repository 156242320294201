//images
import Logo_ViettinBank from "../assets/images/Vietinbank.png";
import Logo_TPBank from "../assets/images/TPBank.png";
import Logo_OCB from "../assets/images/OCB.png";
import Logo_FacePay from "../assets/images/Facepay.png";
import Logo_SmartPay from "../assets/images/SmartPay.png";
import Logo_DefautBank from "../assets/images/Default_Bank.png";
import { FACEPAY } from "./keys";

//bank name
export const TPBANK = "TPB";
export const OCB = "OCB";
export const SMARTPAY = "SMARTPAY";
export const VIETTINBANK = "CTG";
export const PVCOMBANK = "PVCB";
export const BIDV = "BIDV";

export const listBank = [
  {
    logo: Logo_ViettinBank,
    logoDefault: Logo_DefautBank,
    shortName: "ViettinBank",
    fullName: "Ngân hàng Thương mại Cổ Phần Công Thương Việt Nam",
    key: VIETTINBANK,
  },
  {
    logo: Logo_TPBank,
    logoDefault: Logo_DefautBank,
    shortName: "TPBank",
    fullName: "Ngân hàng Thương mại Cổ Phần Tiên Phong",
    key: TPBANK,
  },
  {
    logo: Logo_OCB,
    logoDefault: Logo_DefautBank,
    shortName: "OCB",
    fullName: "Ngân hàng Thương mại Cổ Phần Phương Đông",
    key: OCB,
  },
  {
    logo: Logo_FacePay,
    logoDefault: Logo_DefautBank,
    shortName: "FacePay",
    fullName: "Ví điện tử FacePay",
    key: FACEPAY,
  },
  {
    logo: Logo_SmartPay,
    logoDefault: Logo_DefautBank,
    shortName: "SmartPay",
    fullName: "Ví điện tử SmartPay",
    key: VIETTINBANK,
  }
];
