import styled from "styled-components";
import { WrapTransHistoryDetailsItem } from "../index.styles";

const WrapCustomerTransHistoryDetailsItem = styled(WrapTransHistoryDetailsItem)`
    .info-face-registration{
        .wrap-value{
            display: flex;
            align-items: center;
            gap: 10px;
            justify-content: flex-end;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 11px;
            line-height: 15px;
            letter-spacing: -0.02em;
            color: #313131;
            position: relative;
            .wrap-value-note{
                display: flex;
                gap: 6px;
            }
        }
    }
    .ic-note{
        cursor: pointer;
    }

`

export {
    WrapCustomerTransHistoryDetailsItem
}