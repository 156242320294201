import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDatabase, ref, onValue, off } from "firebase/database";

// component
import Content from './Content'
import Header from './Header'
import SideMenu from './SideMenu'
import Loading from './Loading';

// styles
import { WrapMain } from './index.styles'

// action
import { getListReason } from '../../redux/censorship/listReason/action';
import { DEV_ENV, env, WEE_ENV } from '../../constant/environmentTypes'

// constant
import { refNotifyDevice } from '../../constant/host';

// firebase
import app, { getFileStorage } from '../../firebase/firebase'
import { getListFirebaseDevices, resetIsCheckcSnapshot, resetIsCheckcSreenshot } from '../../redux/deviceV2/listDevicesByAdmin/action';
import Notify from '../Notification/Notify';

const Main = () => {
    let isListenDevice = useRef(true)

    const dispatch = useDispatch()
    const [prevURL, setPrevURL] = useState()

    const { isLoading, isCheckcSreenshot, listFirebase, isCheckSnapshot } = useSelector(state => ({
        isLoading: state.loading.isLoading,
        listFirebase: state.deviceReducer.listDevicesByAdmin.listFirebase,

        // check Sreen
        isCheckcSreenshot: state.deviceReducer.listDevicesByAdmin.isCheckcSreenshot,
        isCheckSnapshot: state.deviceReducer.listDevicesByAdmin.isCheckSnapshot,

    }))

    
    // useSocket()

    useEffect(() => {
        
        if (![DEV_ENV, WEE_ENV].includes(env)) return;
        
        dispatch(getListReason())
        let startDevice = null
        _startListenFirebaseDevice(startDevice)
        if(!isListenDevice.current) isListenDevice.current = true

        return () => {
            startDevice && off(startDevice)
        } 
        
    }, []);


    useEffect(() => {
        let windowObjectReference
        let timer = null

        const checkUrlScreenshot = listFirebase.map(device => device.screenShot).toString()
        setPrevURL(checkUrlScreenshot)

        listFirebase.forEach(device => {
            if(device.screenShot.length > 0 && isCheckcSreenshot){
                windowObjectReference = device.screenShot

                if(prevURL === windowObjectReference) return

                getFileStorage(device.screenShot, (url) => {
                    window.open(url, '_blank')
                    dispatch(resetIsCheckcSreenshot())
                })
                return
            }

            if(device.snapShot.length > 0 && isCheckSnapshot){
                windowObjectReference = device.snapShot

                if(prevURL === windowObjectReference) return

                getFileStorage(device.snapShot, (url) => {
                    window.open(url, '_blank')
                    dispatch(resetIsCheckcSnapshot())
                })
                return
            }
        })

        timer = setInterval(() => {
            if(isCheckcSreenshot) {
                dispatch(resetIsCheckcSreenshot())
                return
            }
            
            if(isCheckSnapshot) {
                dispatch(resetIsCheckcSnapshot())
                return
            }
        }, 30000);

        return () => {
            clearInterval(timer)
        }
    },[listFirebase, isCheckcSreenshot, isCheckSnapshot])

    const _startListenFirebaseDevice = (startDevice) => {
        // console.log(app)
        const db = getDatabase();
        // console.log(db)
        startDevice = ref(db, refNotifyDevice);
        // console.log(startDevice)
        onValue(startDevice, (snapshot) => {
            // console.log(snapshot)
            // if(!isListenDevice.current) return
            // console.log('_startListenFirebaseDevice')
            const data = snapshot.val();
            if (data) {
                const array = Object.values(data)
                dispatch(getListFirebaseDevices(array))
            }
            // isListenDevice.current = false
        });
    }

    return (
        <WrapMain>
            <Header className="header" />
            <SideMenu />
            <Content />
            <Notify />
            { isLoading && <Loading />}
            {/* { isShow && <div><img></img></div>} */}
        </WrapMain>
    )
}

export default Main
