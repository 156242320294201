import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

// css
import { OverlayFullScreen } from "../../../TransactionHistory/TransHistoryItem/PopupShowMedia/index.styles";
import { WrapComfirmReject } from "./index.styles";

// component
import Button from '../../../../../../Control/Button'
import IcConfirm from "../../../../../../../assets/images/IcConfirm";
import { apiGetApprovedUser } from "../../../../../../../redux/censorship/detailCensorship/action";

//api 
import { updateWaitingCondsFilter } from "../../../../../../../redux/censorship/listWaitingCensorship/action";

import * as path from "../../../../../../../constant/path";


const PopupConfirmApprove = ({ onClickClosePopup }) => {
  
  const { censorshipId } = useParams()

  const history = useHistory()
  const dispatch = useDispatch()

  const { user, condFilters, list } = useSelector(state => ({
    user: state.censorshipReducer.detailCensorshipReducer.details.user,
    condFilters: state.censorshipReducer.listWaitingCensorshipReducer.condFilters,
    list: state.censorshipReducer.listWaitingCensorshipReducer.list
  }))
  
  const callbackSuccess = () => {
    const callbackUpdateCondFiltersSuccess = () => {
      history.push(`/${path.MAIN}/${path.CENSORSHIP}/${path.WAITING_CENSORSHIP}`)
    }
    // set filter
    dispatch(updateWaitingCondsFilter({
      condFilters:{
        ...condFilters,
        lastItem: '',
        limit: list.length === 0 ? 15 : list.length
      },
      callbackSuccess: callbackUpdateCondFiltersSuccess
    }))
  }

  const handleSubmit = () => {
    dispatch(apiGetApprovedUser({uid: censorshipId}, callbackSuccess))
    onClickClosePopup()
  }
  return (
    <OverlayFullScreen>
      <WrapComfirmReject>
          <div className="comfirm-title">
              Xác nhận phê duyệt
          </div>
          <div className="confirm-value">
              Bạn vui lòng xác nhận phê duyệt hồ sơ: <span>{user?.fullName}</span>
          </div>
            <div className="confirm">
                <div className="notify">*</div>
                <p>Thông tin phê duyệt sẽ không thể chỉnh sửa</p>
            </div>
            <Button 
                iconBtn={<IcConfirm />}
                type="submit" 
                text="Phê duyệt" 
                className="btn-approved"
                onClick={handleSubmit}
            />
            <Button 
                type="submit" 
                text="Xem lại" 
                className="btn-cancel"
                onClick={onClickClosePopup}
            />
      </WrapComfirmReject>
    </OverlayFullScreen>
  );
};

export default PopupConfirmApprove;
