
export const CHANGE_TAB_TRANSACTION = 'CHANGE_TAB_TRANSACTION'
export const UPDATE_LIST_STORE_NAME_CONDFILTERS = 'UPDATE_LIST_STORE_NAME_CONDFILTERS'
export const UPDATE_LIST_STORE_NAME_TEMP = 'UPDATE_LIST_STORE_NAME_TEMP'

export const GET_LIST_STORE_NAME = 'GET_LIST_STORE_NAME'
export const GET_LIST_STORE_NAME_ERR = 'GET_LIST_STORE_NAME_ERR'

export const GET_LIST_BANK = 'GET_LIST_BANK'
export const GET_LIST_BANK_ERR = 'GET_LIST_BANK_ERR'

export const RESET_STORE_NAME_CONDFILTERS = 'RESET_STORE_NAME_CONDFILTERS'
export const RESET_LIST_STORE_NAME = 'RESET_LIST_STORE_NAME'

// POPUP
export const SHOW_POPUP_DROPDOWN_STORE_NAME = 'SHOW_POPUP_DROPDOWN_STORE_NAME'

export const HIDE_POPUP_DROPDOWN_STORE_NAME = 'HIDE_POPUP_DROPDOWN_STORE_NAME'

export const IS_CHECK_SEARCH_STORE_NAME = 'IS_CHECK_SEARCH_STORE_NAME' 
export const RESET_IS_CHECK_SEARCH_STORE_NAME = 'RESET_IS_CHECK_SEARCH_STORE_NAME' 
