import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

//styled
import { WrapPopupConfirm } from './index.style'

//component
import Button from '../../../../../../../../../Control/Button'

//image
import IcInfoDefault from '../../../../../../../../../../assets/images/IcInfoDefault'
import { OverlayFullScreen } from '../../../../../../TransactionHistory/TransHistoryItem/PopupShowMedia/index.styles'
import { getListEkycHistoryByCustomer, requestUpdateByCustomer } from '../../../../../../../../../../redux/customer/customerDetail/action'
import { BANK, FACEPAY } from '../../../../../../../../../../constant/keys'
import { getListCustomerBankBacklog, getListCustomerFacepayBacklog, resetListCustomerBankBacklog, resetListCustomerFacepayBacklog } from '../../../../../../../../../../redux/customer/customerBacklog/action'

const PopupConfirm = ({ item, onClickClosePopup }) => {

    const dispatch = useDispatch()
    const { details, reqType, condFiltersBacklog } = useSelector((state) => ({
        details: state.customerReducer.customerDetailReducer.details,
        reqType: state.customerReducer.listCustomerReducer.reqType,
        condFiltersBacklog: state.customerReducer.customerBacklogReducer.condFilters
      }))

    const callbackSuccess = () => {
        dispatch(getListEkycHistoryByCustomer({uid: details.id}))
        switch (reqType) {
            case FACEPAY:
                const callbackSuccessFacepayBacklog = () => {
                    if(condFiltersBacklog.uid){
                        dispatch(getListCustomerFacepayBacklog({
                            adminId: condFiltersBacklog.adminId,
                            adminUserName: condFiltersBacklog.adminUserName,
                            uid: condFiltersBacklog.uid,
                            type: condFiltersBacklog.type.key,
                            skip: condFiltersBacklog.skip,
                            limit: condFiltersBacklog.limit,
                            begin: condFiltersBacklog.begin,
                            end: condFiltersBacklog.end
                        }))
                    }
                }
                dispatch(resetListCustomerFacepayBacklog(callbackSuccessFacepayBacklog))
                break;
            case BANK:
                // const callbackSuccessBankBacklog = () => {
                //     if(condFiltersBacklog.bioId){
                //         dispatch(getListCustomerBankBacklog({
                //             adminId: condFiltersBacklog.adminId,
                //             adminUserName: condFiltersBacklog.adminUserName,
                //             bioId: condFiltersBacklog.bioId,
                //             type: condFiltersBacklog.type.key,
                //             skip: condFiltersBacklog.skip,
                //             limit: condFiltersBacklog.limit,
                //             begin: condFiltersBacklog.begin,
                //             end: condFiltersBacklog.end
                //         }))
                //     }
                // }
                // dispatch(resetListCustomerBankBacklog(callbackSuccessBankBacklog))
                break;
         }
        }

    const handleSubmit = () => {
        dispatch(requestUpdateByCustomer({uid: details.id}, callbackSuccess))
        onClickClosePopup()
    }

    return (
        <OverlayFullScreen>
            <WrapPopupConfirm>
                <div className="popup-title">
                    Yêu cầu cập nhật giấy tờ
                </div>
                <div className="popup-value">
                    Bạn vui lòng xác nhận gửi yêu cầu cập nhật giấy tờ mới tới khách hàng: <span>{details?.fullName}</span>
                </div>
                <div className="popup-btn">
                    <Button
                        text="Xác nhận"
                        iconBtn={<IcInfoDefault />}
                        className="btn-confirm"
                        onClick={handleSubmit}
                    />
                    <Button
                        text="Xem lại"
                        className="btn-return"
                        onClick={onClickClosePopup}
                    />
                </div>
            </WrapPopupConfirm>
        </OverlayFullScreen>
    )
}

export default PopupConfirm
