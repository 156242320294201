import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

//styles
import * as styles from './index.styles'

//component
import FacepayCustomerList from './FacepayCustomerList'
import BankCustomerList from './BankCustomerList'
import TabListMenu from '../../../../../Control/TabListMenu'

// constant
import { DEV_SV, QC_SV, PROD_SV, SBX_SV } from '../../../../../../constant/environmentTypes'
import { reqTypeCustomer } from '../../../../../../redux/customer/listCustomer/actionV2'

// constant
import { BANK, FACEPAY } from '../../../../../../constant/keys'

const dataTab = [
    {
        title: 'Facepay',
        key: FACEPAY,
        component: {
            type: 'FACEPAY',
            comp: <FacepayCustomerList />
        },
        envServerValid: [
            DEV_SV,
            QC_SV,
            PROD_SV,
            SBX_SV
        ]
    },
    {
        title: 'Ngân hàng',
        key: BANK,
        component: {
            type: "BANK",
            comp: <BankCustomerList />}
        ,
        envServerValid: [
            DEV_SV,
            QC_SV,
            PROD_SV,
            SBX_SV
        ]
    },
]

export default function CustomerList() {
    const dispatch = useDispatch()
   
    const [component, setComponent] = useState(null)

    const _handleChangeTab = (component) => {
        const callbackSuccess = () => {
            setComponent(component.comp)
        }

        dispatch(reqTypeCustomer({
            reqType: component.type,
            callbackSuccess
        }))
    }

    return (
        <styles.Bound>
            <div className='block info-expand'>
                <TabListMenu data={dataTab} componentSelected={_handleChangeTab} />
                {component}
            </div>
        </styles.Bound>
    )
}
