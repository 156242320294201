import styled from "styled-components";

const popupWidth = 380

const WrapPopupRejectCensor = styled.div`
    font-family: 'Open Sans';
    font-style: normal;
    width: ${popupWidth}px;
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    z-index: 10;
    /* top: ${props => props.rect? `${props.rect.top}px` : "50px"}; */
    top: 40px;
    /* left: ${props => props.rect? `${props.rect.left - popupWidth + props.rect.btnWidth}px` : "30px"}; */
    right: 0;
    position: absolute;
    .popup-container{
        display: none;
    }
    .popup-header{
        box-shadow: inset 0px -0.55px 0px rgba(0, 0, 0, 0.15);
        padding: 16px 13px 13px 20px;
        .title{
            display: flex;
            justify-content: space-between;
            .title-value{
                font-weight: 600;
                font-size: 15px;
                line-height: 20px;
                letter-spacing: -0.01em;
                color: #313131;
                margin-bottom: 8px;
            }
            .icon-close{
                cursor: pointer;
            }
        }
        .value{         
            font-weight: 400;
            font-size: 11px;
            line-height: 15px;
            letter-spacing: -0.02em;
            color: #777777;
        }
    }
    .popup-content{
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 20px 20px 29px 20px;
        .content-item{
            display: flex;
            align-items: center;
            gap: 10px;
            .content-value{
                font-weight: 400;
                font-size: 13.5px;
                line-height: 18px;
                letter-spacing: -0.01em;
                color: #313131;
            }
            .content-icon{
                display: flex;
                align-items: center;
            }
        }
        .wrap-reasion{
            width: 340px;
            height: 54px;
            textarea{
                width: calc(100% - 22px);
                height: calc(100% - 36px);
                background: #FFFFFF;
                border-radius: 4px;
                border: 0.5px solid #B7B7B7;
                padding: 11px 11px 25px 10px;
                outline: unset;
                resize: none;
                font-family: 'Open Sans';
                &::placeholder{
                    font-weight: 400;
                    font-size: 13.5px;
                    line-height: 18px;
                    letter-spacing: -0.01em;
                    color: #B7B7B7;

                }
            }
            .reason-notice{
                font-weight: 400;
                font-size: 13.5px;
                line-height: 18px;
                letter-spacing: -0.01em;
                color: #F45C5C;
            }
        }
    }
    .popup-bottom{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 20px 20px 20px;
        box-shadow: inset 0px 0.5px 0px rgba(0, 0, 0, 0.15);
        .bottom-left{
            display: flex;
            align-items: center;
            gap: 6px;
            .bottom-number{
                width: 60px;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 6px;
                background: #FFEEEE;
                border-radius: 20px;
                padding: 3px;
                .icon-close{
                    width: 15px;
                    height: 15px;
                }
                p{
                    font-weight: 600;
                    font-size: 13.5px;
                    line-height: 18px;
                    letter-spacing: -0.01em;
                    color: #313131;
                }
            }
            .bottom-value{
                font-weight: 600;
                font-size: 11px;
                line-height: 15px;
                letter-spacing: -0.02em;
                color: #313131;
            }
        }
        .btn-reject{
            width: 88px;
            height: 36px;
            background: #F7F7F7;
            font-weight: 600;
            font-size: 13.5px;
            line-height: 18px;
            text-align: center;
            letter-spacing: -0.01em;
            color: #777777;

        }
        .active{
            background: #F45C5C;
            border-radius: 4px;
            font-weight: 600;
            font-size: 13.5px;
            line-height: 18px;
            letter-spacing: -0.01em;
            color: #FFFFFF;
        }
    }
`

export {    
    WrapPopupRejectCensor
}