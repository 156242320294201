import styled from "styled-components";

const WrapPopupWalletFacepay = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    width: 710px;
    height: 562px;
    background: #FFFFFF;
    border-radius: 4px;
    background: #F7F7F7;
    padding: 14px;
    
    font-family: 'Open Sans';
    font-style: normal;

    .transaction-value{
        font-weight: 600;
        font-size: 11px;
        line-height: 15px;
        letter-spacing: -0.02em;
        color: #313131;

        display: flex;
        align-items: center;
        gap: 4px;
    }
    span{
        font-weight: 400;
        font-size: 11px;
        line-height: 15px;
        display: flex;
        align-items: center;
        text-align: right;
        letter-spacing: -0.02em;
        color: #777777;
    }

    .text-value{
        display: flex;
        gap: 6px;
        align-items: center;
    }
    .value{
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 13.5px;
        line-height: 18px;
        letter-spacing: -0.01em;
        color: #313131;
    }
    .popup-title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title-left{
            display: flex;
            align-items: center;
            gap: 6px;
            .title-code{
                font-weight: 600;
                font-size: 15px;
                line-height: 20px;
                letter-spacing: -0.01em;
                color: #313131;
            }
            .title-value{
                font-weight: 400;
                font-size: 15px;
                line-height: 20px;
                letter-spacing: -0.01em;
                color: #313131;
            }
        }
        .title-right{
            font-weight: 400;
            font-size: 13.5px;
            line-height: 18px;
            letter-spacing: -0.01em;
            color: #777777;
        }
    }
    .popup-content{
        margin-top: 12px;
        .content-transaction{
            height: 184px;
            background: #FFFFFF;
            border-radius: 4px;
            padding: 10px;
            display: flex;
            flex-direction: column;
            gap: 40px; 

            .transaction-title{
                display: flex;
                justify-content: space-between;
                align-items: center;

                .transaction-title-right{
                    display: flex;
                    gap: 10px;
                }
            }

            .transaction-dashline-content{
                padding: 31px;
                position: relative;

                .dashline-wallet{
                    width: calc(100% - 40px);
                    border: 1px dashed #B7B7B7;
                }

                .content-status{
                    width: calc(100% - 40px);
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    position: absolute;
                    top: 16px;
                    right: 20px;
                    .status{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 3px;
                        .icon{
                            width: 32px;
                            height: 32px;
                            margin-bottom: 7px;
                            svg{
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .time{
                            font-weight: 400;
                            font-size: 10px;
                            line-height: 14px;
                            letter-spacing: -0.02em;
                            color: #777777;
                        }
                        .success{
                            color: #33ADFF;
                        }
                        .failed{
                            color: #F45C5C;
                        }
                    }
                }
            }
        }

        .content-wallet{
            display: flex;
            gap: 8px;
            margin-top: 12px;
            .wallet-bank{
                width: 453px;
                height: 300px;
                background: #FFFFFF;
                padding: 10px;
                .transaction-icon{
                    width: 14px;
                    height: 14px;
                    svg{
                        width: 100%;
                        height: 100%;
                    }
                }
                .wallet-content{
                    padding: 20px 0 0 0;
                    display: grid;
                    gap: 12px;
                    grid-template-columns: repeat(2,180px);
                    gap: 20px;
                }
            }
            .info-verify{
                width: 260px;
                height: 300px;
                background: #FFFFFF;
                padding: 10px;
                .info-content{
                    padding: 20px 0 0 0;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                }
            }
            .info{
                display: flex;
                align-items: center;
                gap: 8px;
                .content{
                    display: flex;
                    flex-direction: column;
                    gap: 2px;
                }
            }
        }

    }
`

export {
    WrapPopupWalletFacepay
}