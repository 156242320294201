import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

// image
import DevicesInfo from '../../../../../../assets/images/img_devices_info.png'
import IcMoreOption from '../../../../../../assets/images/IcMoreOption'
import IcWifi from '../../../../../../assets/images/IcWifi'
import IcEthBlue from '../../../../../../assets/images/IcEthBlue'

// styles
import { WrapPopupDeviceInfos } from './index.styles'

// data
import dataDeviceStatus from '../../../../../../data/dataDeviceStatus'

// component
import Portal from '../../../../../Control/Portal'
import PopupDeviceDetail from '../PopupDeviceDetail'
import DropdownFeatureDevidce from '../DropdownFeatureDevice'

// redux
import { detailsDevice } from '../../../../../../redux/deviceV2/listDevicesByAdmin/action'

// keys
import { POPUP_DEVICE } from '../../../../../../constant/keys'

const PopupDeviceInfos = ({ data, isShowDropdownFeatureDevice, setIsShowDropdownFeatureDevice, isShowDropdownFeatureDevicePopup, setIsShowDropdownFeatureDevicePopup, type, imageUrl }) => {

    const dispatch = useDispatch()

    const [isShowPopupDeviceDetail, setIsShowPopupDeviceDetail] = useState(false)


    const objStatus = dataDeviceStatus[data.status] || {}
    const { color, vnText } = objStatus

    const handleShowPopupDeviceDetail = () => {
        setIsShowPopupDeviceDetail(true)
        dispatch(detailsDevice(data))
    }

    const handleShowFeatureDevice = () => {
        if(type === POPUP_DEVICE) {
            setIsShowDropdownFeatureDevicePopup(true)
        }else{
            setIsShowDropdownFeatureDevice(true)
        }
    }



    return (
        <WrapPopupDeviceInfos 
        colorByStatus={color}
        >
            <div className="wrap-row">
                <div className='wrap-row-left'>
                    <div className="image">
                        <img src={DevicesInfo} alt="device"/>
                    </div>
                    <div className="wrap-column">
                        <div className="device-name" title={data.appInfo?.name}>    {data.appInfo?.name || "-"}
                        </div>
                        <div className="device-icon">
                            {
                                data.ipAddress?.wlan0 ? <IcWifi /> : 
                                data.ipAddress?.eth0 ? <IcEthBlue /> : 
                                data.ipAddress?.wlan0 && data.ipAddress?.eth0 ? (
                                    <>
                                        <IcWifi /> <IcEthBlue />
                                    </>
                                ): ""
                            }
                        </div>
                    </div>
                </div>
                <div className='wrap-row-right'>
                    <div className="icon" onClick={handleShowFeatureDevice}>
                        <IcMoreOption />
                    </div>
                    {
                        isShowDropdownFeatureDevice || isShowDropdownFeatureDevicePopup && (
                            <DropdownFeatureDevidce
                                onCloseDropdown={() => setIsShowDropdownFeatureDevice(false)}
                                checkShowFeatureDevice={true}
                                data={data}
                            />
                        )
                    }
                    <div className="device-detail" onClick={handleShowPopupDeviceDetail}>Chi tiết</div>
                </div>
            </div>
            <div className="wrap-field">
                <div className="field-name">DeviceID</div>
                <div className="field-value" >{data.appInfo?.linkCode || "-"}</div>
            </div>
            <div className="wrap-field">
                <div className="field-name">Trạng thái</div>
                <div className="field-value color-status" >{vnText || "-"}</div>
            </div>
            <div className="wrap-field">
                <div className="field-name">Cửa hàng</div>
                <div className={`field-value`} >{data.appInfo?.shopName || "-"}</div>
            </div>
            <div className="wrap-field">
                <div className="field-name">Location</div>
                <a className='field-value link' href={`https://www.google.com/maps/place/${data.appInfo?.lat},${data.appInfo?.lng}`} target='_blank'>
                    {`https://www.google.com/maps/place/${data.appInfo?.lat},${data.appInfo?.lng}`} 
                </a>
            </div>
            {
                isShowPopupDeviceDetail ? (
                    <Portal>
                        <PopupDeviceDetail 
                            imageUrl={imageUrl}
                            onClosePopup={() => setIsShowPopupDeviceDetail(false)}
                            data={data}
                            objStatus={objStatus}
                        />
                    </Portal>
                ) : ""
            }
        </WrapPopupDeviceInfos>
    )
}

export default PopupDeviceInfos
