import styled from "styled-components";
import {WrapTransactionItem } from '../../Bank/TransactionItem/index.styles'


const WrapTransactionWalletItem = styled(WrapTransactionItem)`
    grid-template-columns: minmax(95px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(175px, 1fr) minmax(90px, 1fr) minmax(100px, 1fr) minmax(95px, 1fr) 130px;

    .wrap-item{
        display: flex;
        align-items: center;
        gap: 6px;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 13.5px;
        line-height: 18px;
        text-align: justify;
        letter-spacing: -0.01em;
        &.status{
            justify-content: space-between;
        }
        .item-icon{
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            img{
                width: 100%;
                height: 100%;
            }
            svg{
                width: 100%;
                height: 100%;
            }
        }
        .bold{
            font-weight: 600;
            font-size: 13.5px;
            line-height: 18px;
            letter-spacing: -0.01em;

            color: #313131;
        }
        .success{
            padding: 3px 8px;
            gap: 10px;
            background: #EFF9FF;
            border-radius: 24px;
            color: #33ADFF;
        }
        .failed{
            padding: 3px 8px;
            gap: 10px;
            background: #FFEEEE;
            border-radius: 24px;
            color: #F45C5C;
        }
    }

`

export {
    WrapTransactionWalletItem
}