import React, { useState, useReducer, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

//style
import {
  WrapPage,
  WrapFilterTransHistory,
  WrapDateRange,
} from "./index.styles";

//helper
import removeAccent from "../../../../../../../../helper/removeAccent";
import trimStartAndStripStr from "../../../../../../../../helper/trimStartAndStripStr";

//image
import IcFilterBlue from "../../../../../../../../assets/images/IcFilterBlue";
import IcExportFileBlue from "../../../../../../../../assets/images/IcExportFileBlue";
import IcCalendarActive from "../../../../../../../../assets/images/IcCalendarActive";
import IcClose from "../../../../../../../../assets/images/IcClose";
import IcUnCheckBox from "../../../../../../../../assets/images/IcUnCheckBox";
import IcCheckBoxActive from "../../../../../../../../assets/images/IcCheckBoxActive";
import IcResetBlue from "../../../../../../../../assets/images/IcResetBlue";
import IcArrowDownGray from "../../../../../../../../assets/images/IcArrowDownGray";

//component
import InputSearch from "../../../../../../../Control/InputSearch";
import CheckBoxControl from "../../../../../../../Control/CheckBoxControl";

//action
import {
  exportTransactionBankOfCustomer,
  exportTransactionFacepayOfCustomer,
  updateListTransactionOfCustomerCondsFilter,
} from "../../../../../../../../redux/customer/customerTransactionHistory/actionV2";
import {
  currentDay,
  startDay,
} from "../../../../../../../../redux/customer/customerTransactionHistory/customerTransactionHistoryReducer";

// constant
import { FACEPAY } from "../../../../../../../../constant/keys";
import DatePickerItem from "../../../../../../../Control/DatePickerItem";

const iconCheckBox = {
  icon: IcUnCheckBox,
  iconActive: IcCheckBoxActive,
};

const FilterTransHistory = () => {
  const dispatch = useDispatch();

  const { details, condFilters, reqType } = useSelector((state) => ({
    details: state.customerReducer.customerDetailReducer.details,
    condFilters: state.customerReducer.customerTransactionHistoryReducer.condFilters,
    reqType: state.customerReducer.listCustomerReducer.reqType,

  }));

  const [isExpandFilter, setIsExpandFilter] = useState(false);

  const [data, setData] = useReducer(
    (state, action) => ({ ...state, ...action }),
    {
      bioId: condFilters.bioId,
      uid: condFilters.uid,
      facepayTrxCode: condFilters.facepayTrxCode,
      begin: condFilters.begin,
      end: condFilters.end,
      isSuccess: condFilters.isSuccess,
      isFail: condFilters.isFail,
      supplier: condFilters.supplier
    }
  );

  useEffect(() => {
    if (!details?.id) return
    if (reqType === FACEPAY) {
      dispatch(
        updateListTransactionOfCustomerCondsFilter({
          condFilters: {
            uid: data.uid,
            facepayTrxCode: data.facepayTrxCode,
            begin: data.begin,
            end: data.end,
            isSuccess: data.isSuccess,
            isFail: data.isFail,
            skip: 0,
            limit: 15,
          },
          reqType,
        })
      );
    } else {
      dispatch(
        updateListTransactionOfCustomerCondsFilter({
          condFilters: {
            bioId: data.bioId,
            facepayTrxCode: data.facepayTrxCode,
            begin: data.begin,
            end: data.end,
            isSuccess: data.isSuccess,
            isFail: data.isFail,
            skip: 0,
            limit: 15,
          },
          reqType,
        })
      );
    }
  }, [data.bioId, data.uid, data.begin, data.end, data.isSuccess, data.isFail, JSON.stringify(condFilters)]);

  useEffect(() => {
    setData({
      bioId: details?.id,
      uid: details?.id,
      facepayTrxCode: "",
      begin: startDay,
      end: currentDay,
      isSuccess: true,
      isFail: true,
    });
    setIsExpandFilter(false);
  }, [details?.id]);

  const _handleReset = () => {
    setData({
      bioId: condFilters.bioId,
      uid: condFilters.uid,
      facepayTrxCode: "",
      begin: startDay,
      end: currentDay,
      isSuccess: true,
      isFail: true,
      supplier: ["*"]
    });
    setIsExpandFilter(false);
    // condFilters.swiftCode.filter((item) => item.isSelect = true)
  };

  const _handleSearch = () => {
    if (reqType === FACEPAY) {
      dispatch(
        updateListTransactionOfCustomerCondsFilter({
          condFilters: {
            uid: data.uid,
            facepayTrxCode: data.facepayTrxCode,
            begin: data.begin,
            end: data.end,
            isSuccess: data.isSuccess,
            isFail: data.isFail,
            skip: 0,
            limit: 15,
            supplier: data.supplier
          },
          reqType,
        })
      );
    } else {
      dispatch(
        updateListTransactionOfCustomerCondsFilter({
          condFilters: {
            bioId: data.bioId,
            facepayTrxCode: data.facepayTrxCode,
            begin: data.begin,
            end: data.end,
            isSuccess: data.isSuccess,
            isFail: data.isFail,
            skip: 0,
            limit: 15,
          },
          reqType,
        })
      );
    }
  };

  const _handleExportFile = () => {
    if(reqType === FACEPAY) {
      dispatch(
        exportTransactionFacepayOfCustomer({
          uid: condFilters.uid,
          supplier: ["*"],
          facepayTrxCode: condFilters.facepayTrxCode,
          limit: condFilters.limit,
          skip: condFilters.skip,
          begin: condFilters.begin,
          end: condFilters.end,
          isSuccess: condFilters.isSuccess,
          isFail: condFilters.isFail
        })
      );
    }else {
      dispatch(
        exportTransactionBankOfCustomer({
          data: {
            bioId: condFilters.bioId,
            facepayTrxCode: condFilters.facepayTrxCode,
            begin: condFilters.begin,
            end: condFilters.end,
            isSuccess: condFilters.isSuccess,
            isFail: condFilters.isFail,
          },
        })
      );
    }
    
  };

  // const _handleSelectCheckbox = (checkboxSelected) => {
  //   if(!checkboxSelected) return
  //   if(!condFilters.swiftCode) return
    
  //   let checkboxCollection = condFilters.swiftCode.map((item) => {
  //       if(item.key !== checkboxSelected){
  //           return {
  //               ...item
  //           }
  //       }else {
  //           return {
  //               ...item,
  //               isSelect: !item.isSelect,
  //           }
  //       }
  //   })
  //   dispatch(updateListTransactionOfCustomerCondsFilter({
  //       condFilters: {
  //           ...condFilters,
  //           swiftCode: checkboxCollection
  //         },
  //   }))
  // };


  return (
    <WrapPage>
      <WrapFilterTransHistory>
        <InputSearch
          value={data.facepayTrxCode}
          type="text"
          placeholder="Nhập mã Facepay Tx ID..."
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              _handleSearch();
            }
          }}
          handleSearch={_handleSearch}
          onChange={(e) =>
            setData({
              facepayTrxCode: removeAccent(
                trimStartAndStripStr(e.target.value)
              ),
            })
          }
        />
        <WrapDateRange>
          <IcCalendarActive />
          <DatePickerItem
            className="input-dropdown"
            selected={data.begin}
            onChange={(date) =>
              setData({
                begin: date ? moment(date).startOf("day").toDate() : undefined,
              })
            }
            maxDate={data.end ? data.end : new Date()}
            textEmpty="Từ ngày"
            dateFormat="dd/MM/yyyy"
            showTimeSelect={false}
          />
          <IcArrowDownGray className="arrow-right" />
          <DatePickerItem
            className="input-dropdown "
            selected={data.end}
            onChange={(date) =>
              setData({
                end: date ? moment(date).endOf("day").toDate() : undefined,
              })
            }
            minDate={data.begin}
            maxDate={new Date()}
            textEmpty="Đến ngày"
            dateFormat="dd/MM/yyyy"
            showTimeSelect={false}
          />
        </WrapDateRange>
        <IcFilterBlue
          className="icon"
          onClick={() => setIsExpandFilter((pre) => !pre)}
        />
        <div title="Xuất file dữ liệu">
          <IcExportFileBlue
            className="icon"
            title="Xuất file dữ liệu"
            onClick={_handleExportFile}
          />
        </div>
      </WrapFilterTransHistory>
      {isExpandFilter && (
        <div className="wrap-expand-filter">
          <div className="wrap-button">
            <p className="title-filter">Bộ lọc mở rộng</p>
            <IcClose
              className="icon-close"
              onClick={() => setIsExpandFilter(false)}
            />
            <div className="btn-reset" onClick={_handleReset}>
              <IcResetBlue />
              <p className="reset">Reset</p>
            </div>
          </div>
          <div className="wrap-filter">
            {/* {reqType === FACEPAY && (
              <div className="filter">
                <p className="title">Loại thẻ</p>
                <div className="wrap-checkbox">
                  {
                    condFilters &&
                        condFilters.swiftCode.map((item, index) => (
                        <CheckBoxControl
                            key={index}
                            iconCheckBox={iconCheckBox}
                            className="info-check"
                            isChecked={item.isSelect}
                            handleCheck={() => _handleSelectCheckbox(item.key)}
                            title={item.name}
                        />
                        ))
                  }
                </div>
              </div>
            )} */}
            <div className="filter">
              <p className="title">Trạng thái</p>
              <div className="wrap-checkbox">
                <CheckBoxControl
                  iconCheckBox={iconCheckBox}
                  className="info-check"
                  isChecked={data.isSuccess}
                  handleCheck={() => {
                    setData({ isSuccess: !data.isSuccess });
                  }}
                  title="Thành công"
                />
                <CheckBoxControl
                  iconCheckBox={iconCheckBox}
                  className="info-check"
                  isChecked={data.isFail}
                  handleCheck={() => {
                    setData({ isFail: !data.isFail });
                  }}
                  title="Thất bại"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </WrapPage>
  );
};

export default FilterTransHistory;
