import React, { useState, useEffect, useReducer } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Switch, Route, useParams, useRouteMatch } from 'react-router-dom'
import { getDatabase, ref, onValue, off} from "firebase/database";

// constant
import { refNotifySummaryFacepayService, refNotifySummaryCloudServer } from "../../../../constant/host";
import * as path from "../../../../constant/path";
import { WEE_ENV, DEV_ENV, env, envServer, DEV_SV } from '../../../../constant/environmentTypes';

// styles
import { WrapHeaderContent } from "./index.styles";

//help
import numberFormatToThousand from "../../../../helper/numberFormatToThousand";
//image
import IcMerchantLogoDefault from "../../../../assets/images/IcMerchantLogoDefault";
import IcLogoFacepayBlue from '../../../../assets/images/IcLogoFacepayBlue'
//action
import { getTransactionCount } from "../../../../redux/transaction/transactionCount/action";
import { getBankCount } from "../../../../redux/bank/bankCount/action";
import { getMerchantCount } from "../../../../redux/merchant/merchantCount/action";
import { getDeviceCount } from "../../../../redux/device/deviceCount/action";
import { getCustomerCount } from "../../../../redux/customer/customerCount/action";
import { getCensorshipCount } from "../../../../redux/censorship/censorshipCount/action";
import numberRoundToTwo from '../../../../helper/numberRoundToTwo';


// const dataNumberTrans = 313242
// const ImageBank = `https://picsum.photos/100/100`

// const TotalTransaction = () => {
//   const dispatch = useDispatch();

//   const { total } = useSelector((state) => ({
//     total: state.transactionReducer.transactionCountReducer.total,
//   }));

//   const [stringTransaction, setStringTransaction] = useState("");

//   useEffect(() => {
//     dispatch(getTransactionCount());
//   }, []);

//   useEffect(() => {
//     if (total <= 1) {
//       setStringTransaction("Transaction");
//     } else if (total > 1) {
//       setStringTransaction("Transactions");
//     }
//   }, [total]);

//   return (
//     <>
//       {numberFormatToThousand(total)} {stringTransaction}
//     </>
//   );
// };

const TotalTransactionV2 = () => {

  const [summaryState, setSummaryState] = useReducer(
    (state, action) => ({ ...state, ...action }),
    {
      totalSales: 0,
      totalTransaction: 0,
      totalTransactionSucceed: 0,
      totalTransactionFailed: 0,
    }
  )
  
  useEffect(() => {
    if(![DEV_ENV, WEE_ENV].includes(env)) return
    let startListenCloudServer
    _startListenFirebaseCloudServer(startListenCloudServer)
    return () => {
      startListenCloudServer && off(startListenCloudServer)
    }
  }, []);

  const _startListenFirebaseCloudServer = (startListenCloudServer) => {
    const db = getDatabase();
   
    startListenCloudServer = ref(db, refNotifySummaryCloudServer);
   
    onValue(startListenCloudServer, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setSummaryState({      
          totalSales: data.TotalRevenue || 0,
          totalTransaction: data.TotalTransaction || 0,
          totalTransactionSucceed: data.TotalTransactionSucceed || 0,
          totalTransactionFailed: data.TotalTransaction - data.TotalTransactionSucceed || 0
        })
      }
    });
  }
  return (
    <div className="list-trans-total">
      <div>
        <p className="num">{numberFormatToThousand(summaryState.totalSales)}</p>
        <p className="text">Tổng doanh số (vnđ)</p>
      </div>
      <div>
        <p className="num">{numberFormatToThousand(summaryState.totalTransaction)}</p>
        <p className="text">Tổng giao dịch</p>
      </div>
      <div>
        <p className="num">{numberFormatToThousand(summaryState.totalTransactionSucceed)}</p>
        <p className="text success">{`Thành công (${numberRoundToTwo(summaryState.totalTransactionSucceed/summaryState.totalTransaction * 100)}%)`}</p>
      </div>
      <div>
        <p className="num">{numberFormatToThousand(summaryState.totalTransactionFailed)}</p>
        <p className="text fail">{`Thất bại (${numberRoundToTwo(summaryState.totalTransactionFailed/summaryState.totalTransaction * 100)}%)`}</p>
      </div>
    </div>
  );
}

const TotalBank = () => {
  const dispatch = useDispatch();

  const { total } = useSelector((state) => ({
    total: state.bankReducer.bankCountReducer.total,
  }));

  const [stringBank, setStringBank] = useState("");


  useEffect(() => {
    dispatch(getBankCount());
  }, []);

  useEffect(() => {
    if (total <= 1) {
      setStringBank("Bank");
    } else if (total > 1) {
      setStringBank("Banks");
    }
  }, [total]);

  return (
    <>
      {numberFormatToThousand(total)} {stringBank}
    </>
  );
};

const TotalMerchant = () => {
  const dispatch = useDispatch();

  const { total } = useSelector((state) => ({
    total: state.merchantReducer.merchantCountReducer.total,
  }));

  const [stringMerchant, setStringMerchant] = useState("");

  useEffect(() => {
    dispatch(getMerchantCount());
  }, []);

  useEffect(() => {
    if (total <= 1) {
      setStringMerchant("Merchant");
    } else if (total > 1) {
      setStringMerchant("Merchants");
    }
  }, [total]);

  return (
    <>
      {numberFormatToThousand(total)} {stringMerchant}
    </>
  );
};

const TotalDevice = () => {

  const { listFirebase } = useSelector((state) => ({
    listFirebase: state.deviceReducer.listDevicesByAdmin.listFirebase,
  }));

  const [stringDevice, setStringDevice] = useState("");

  useEffect(() => {
    if (listFirebase.length <= 1) {
      setStringDevice("thiết bị");
    } else if (listFirebase.length > 1) {
      setStringDevice("thiết bị");
    }
  }, [listFirebase.length]);

  return (
    <>
      {numberFormatToThousand(listFirebase.length)} {stringDevice}
    </>
  );
};

const TotalCustomer = () => {
  const dispatch = useDispatch();

  const { total } = useSelector((state) => ({
    total: state.customerReducer.customerCountReducer.total,
  }));

  const [stringCustomer, setStringCustomer] = useState("");

  useEffect(() => {
    dispatch(getCustomerCount());
  }, []);

  useEffect(() => {
    if (total <= 1) {
      setStringCustomer("Customer");
    } else if (total > 1) {
      setStringCustomer("Customers");
    }
  }, [total]);

  return (
    <>
      {numberFormatToThousand(total)} {stringCustomer}
    </>
  );
};

const TotalCustomerV2 = () => {

  const { total } = useSelector((state) => ({
    // total: state.censorshipReducer.censorshipCountReducer.total
  }));

  const [summaryState, setSummaryState] = useReducer(
    (state, action) => ({ ...state, ...action }),
    {
      totalSales: 0,
      totalTransaction: 0,
      totalFacepayCustomer: 0,
      totalBankCustomer: 0
    }
  )
  
  useEffect(() => {
    // dispatch(getCensorshipCount());
  }, []);

  useEffect(() => {
    if(![DEV_ENV, WEE_ENV].includes(env)) return;
    let startListenFacepayService
    _startListenFirebaseFacepayService(startListenFacepayService)
    // if(envServer !== DEV_SV) return
    let startListenCloudServer
    _startListenFirebaseCloudServer(startListenCloudServer)
    return () => {
      startListenFacepayService && off(startListenFacepayService)
      // if(envServer !== DEV_SV) return
      startListenCloudServer && off(startListenCloudServer)
    }
  }, []);

  const _startListenFirebaseFacepayService = (startListenFacepayService) => {
    const db = getDatabase();
   
    startListenFacepayService = ref(db, refNotifySummaryFacepayService);
   
    onValue(startListenFacepayService, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        // console.log(data)
        // if(data.ApproveUsers){
        //   setSummaryState({totalFacepayCustomer: data.ApproveUsers})
        // }
        setSummaryState({
          totalFacepayCustomer: data.ApproveUsers || 0
        })
      }
    });
  }

  const _startListenFirebaseCloudServer = (startListenCloudServer) => {
    const db = getDatabase();
   
    startListenCloudServer = ref(db, refNotifySummaryCloudServer);
   
    onValue(startListenCloudServer, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setSummaryState({      
          totalSales: data.TotalRevenue || 0,
          totalTransaction: data.TotalTransaction || 0,
          totalBankCustomer: data.BankUsers || 0
        })
      }
    });
  }

  return (
    <div className="list-customer-total">
      <div>
        <p className="num">{numberFormatToThousand(summaryState.totalSales)}</p>
        <p className="text">Tổng doanh số (vnđ)</p>
      </div>
      <div>
        <p className="num">{numberFormatToThousand(summaryState.totalTransaction)}</p>
        <p className="text">Tổng giao dịch</p>
      </div>
      <div>
        <p className="num">{numberFormatToThousand(summaryState.totalBankCustomer + summaryState.totalFacepayCustomer)}</p>
        <p className="text">Tổng khách hàng</p>
      </div>
      <div>
        <p className="num">{numberFormatToThousand(summaryState.totalBankCustomer)}</p>
        <p className="text">Khách hàng ngân hàng</p>
      </div>
      <div>
        <p className="num blue">{numberFormatToThousand(summaryState.totalFacepayCustomer)}</p>
        <p className="text">Khách hàng Facepay</p>
      </div>
    </div>
  );
};

const TotalCensorship = () => {

  const dispatch = useDispatch();

  const { total } = useSelector((state) => ({
    total: state.censorshipReducer.censorshipCountReducer.total
  }));

  useEffect(() => {
    // dispatch(getCensorshipCount());
  }, []);


  return (
    <div className="list-censorship">
      <div>
        <p className="num reject">{total.rejectCount}</p>
        <p className="text">Hồ sơ bị từ chối</p>
      </div>
      <div>
        <p className="num wait">{total.pendingCount}</p>
        <p className="text">Chờ kiểm duyệt</p>
      </div>
      <div>
        <p className="num over">{total.pendingThanCount}</p>
        <p className="text">Hồ sơ trên 24h</p>
      </div>
    </div>
  );
};

const TotalWaitingCensorshipDetails = () => {
  return null
}

const TotalRejectionProfileDetails = () => {
  return null
}

const HeaderContent = () => {
  const [isErrLogoBank, setIsErrLogoBank] = useState(false);

  const { supplier, supplierShortName } = useSelector((state) => ({
    supplier: state.loginReducer.dataLogin.supplier,
    supplierShortName: state.loginReducer.dataLogin.supplierShortName,
  }));

  return (
    <WrapHeaderContent>
      <div className="wrap-header-content">
        <div className="bank_info">
          <IcLogoFacepayBlue onError={() => setIsErrLogoBank(true)} />
          <p className="name_bank">
            Facepay
          </p>
        </div>
        <div className="total">
          <Switch>
            <Route path={`/${path.MAIN}/${path.ANALYTIC}`}>
              <TotalTransactionV2 />
            </Route>
            <Route path={`/${path.MAIN}/${path.TRANSACTION}`}>
              <TotalTransactionV2 />
            </Route>
            <Route path={`/${path.MAIN}/${path.DEVICE}`}>
              <TotalDevice />
            </Route>
            <Route path={`/${path.MAIN}/${path.PAYMENT_TERMINAL}`}>
              {/* <TotalDevice /> */}
            </Route>
            <Route path={`/${path.MAIN}/${path.MERCHANT}`}>
              <TotalMerchant />
            </Route>
            <Route path={`/${path.MAIN}/${path.BANK}`}>
              <TotalBank />
            </Route>
            <Route path={`/${path.MAIN}/${path.CUSTOMER}`}>
              <TotalCustomerV2 />
            </Route>
            <Route exact path={[`/${path.MAIN}/${path.CENSORSHIP}`, `/${path.MAIN}/${path.CENSORSHIP}/${path.WAITING_CENSORSHIP}`, `/${path.MAIN}/${path.CENSORSHIP}/${path.REJECTION_PROFILE}`]}>
              <TotalCensorship />
            </Route>
            <Route exact path={`/${path.MAIN}/${path.CENSORSHIP}/${path.WAITING_CENSORSHIP}/:censorshipId`}>
              <TotalWaitingCensorshipDetails />
            </Route>
            <Route exact path={`/${path.MAIN}/${path.CENSORSHIP}/${path.REJECTION_PROFILE}/:censorshipId`}>
              <TotalRejectionProfileDetails />
            </Route>
          </Switch>
        </div>
      </div>
    </WrapHeaderContent>
  );
};

export default HeaderContent;
