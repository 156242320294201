import styled from "styled-components";

const WrapBank = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;

    .wrap-header{
        display: grid;
        grid-template-columns: minmax(95px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(175px, 1fr) minmax(60px, 1fr) minmax(60px, 1fr) minmax(90px, 1fr) minmax(130px, 1fr) minmax(95px, 1fr) 60px;
        grid-gap: 10px;
        padding: 17px 30px 9px 30px;
        box-sizing: border-box;
        width: 100%;
        .header-item{
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 11px;
            line-height: 15px;
            display: flex;
            align-items: center;
            letter-spacing: -0.02em;
            color: #313131;
            :first-of-type{
                padding-left: 16px;
            }
        }
    }

    .wrap-empty {
        flex-grow: 1;
        display: flex;
        align-items: center;
        p{
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 13.5px;
            line-height: 18px;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: -0.01em;
            color: #777777;
        }
    }
    
`
const TableContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 100%;
    padding: 0 30px 30px 30px;

    .wrap-list-transaction {
        width: calc(100% + 10px);
        flex-grow: 1;
        overflow: overlay;
        margin-left: 10px;
        position: relative;
        .list-transaction {
            width: calc(100% - 10px);
            display: flex;
            flex-direction: column;
            position: absolute;
        }
    }
`
export {
    WrapBank,
    TableContent
}