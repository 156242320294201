import React, { useRef, useReducer } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'

// style
import { WrapPopupExportFileV2 } from './index.style'

// images
import IcClose from '../../../../../assets/images/IcClose'
import IcArrowRight from '../../../../../assets/images/IcArrowRight'
import IcCheckBlue from '../../../../../assets/images/IcCheckBlue'
import IcExportFileWhite from '../../../../../assets/images/IcExportFileWhite'
import IcCalendarActive from '../../../../../assets/images/IcCalendarActive'

// component
import DatePickerItem from '../FilterTransaction/DatePickerItem'
import CheckBoxControl from '../../../../Control/CheckBoxControl'
import Button from '../../../../Control/Button'
import { iconCheckBox } from '../PopupExportFileV2/ExportFileBankV2'
import { useOnClickOutside } from '../../../../../hook/useClickOutside'
import { dataSelectCheckBoxExportDevice } from '../../../../../data/dataSelectCheckbox'

// helper
import { ADV_PLAYING, CONNECTED, DEVICE_ID, DEVICE_NAME, FIRMWARE_CAMERA, FIRMWARE_DEVICE, FREE_MOMORY, LAST_ONLINE_TIME, LOCATION, MERCHANT, SERIAL_NUMBER, STATUS_ID, TERMINATED, TOTAL_MEMORY, UNLINKED, VERSION_CODE, VERSION_CODE_LAUNCHER, VERSION_NAME } from '../../../../../constant/keys'
import checkStatusOnline from '../../../../../helper/checkStatusOnline'
import dataDeviceStatus, { deviceStatus } from '../../../../../data/dataDeviceStatus'
import { convertDateTime } from '../../../../../helper/convertDateTime'
import exportExcel from '../../../../../helper/exportExcel'

const PopupExportFileDeviceV2 = ({ onClickClosePopup }) => {

    const filterRef = useRef()

    const { condFiltersExportDevice, listTempFirebase } = useSelector((state) => ({
        condFiltersExportDevice: state.deviceReducer.listDevicesByAdmin.condFiltersExportDevice,
        listTempFirebase: state.deviceReducer.listDevicesByAdmin.listTempFirebase,

    }))

    const [data, setData] = useReducer(
        (state, action) => ({...state, ...action}), 
        {
            begin: condFiltersExportDevice.begin,
            end: condFiltersExportDevice.end,
            OptionSelectCheckboxExportDevice: condFiltersExportDevice.OptionSelectCheckboxExportDevice
        }
    )

    const handleSelectCheckbox = (checkboxSelected) => {
        if(!checkboxSelected) return
        if(!data.OptionSelectCheckboxExportDevice) return

        let checkboxCollection = data.OptionSelectCheckboxExportDevice.map((item) => {
            if(item.id !== checkboxSelected){
                return {
                    ...item
                }
            }else {
                return {
                    ...item,
                    isSelect: !item.isSelect
                }
            }
        })
        setData({
            OptionSelectCheckboxExportDevice: checkboxCollection
        })
    }

    const handleExportFileDevice = () => {

        let listExport = []
        if(listTempFirebase.length > 0){
            for(let i = 0; i < listTempFirebase.length; i++){

                const objStatus = dataDeviceStatus[listTempFirebase[i].status] || {}
    
                const temp = data.OptionSelectCheckboxExportDevice.filter((item) => item.isSelect === true).reduce((a, v) => ({...a, [v.name] : 
                    v.key === STATUS_ID ? deviceStatus[objStatus.type].vnText : 
                    v.key === DEVICE_ID ? listTempFirebase[i].deviceID : 
                    v.key === DEVICE_NAME ? listTempFirebase[i].appInfo?.name : 
                    v.key === MERCHANT ? listTempFirebase[i].appInfo?.shopName : 
                    v.key === LOCATION ? listTempFirebase[i].appInfo?.shopAddress : 
                    v.key === VERSION_NAME ? listTempFirebase[i].appInfo?.versionName : 
                    v.key === VERSION_CODE ? listTempFirebase[i].appInfo?.versionCode : 
                    v.key === SERIAL_NUMBER ? listTempFirebase[i].serialNumber : 
                    v.key === TOTAL_MEMORY ? listTempFirebase[i].verCode : 
                    v.key === VERSION_CODE_LAUNCHER ? listTempFirebase[i].totalMemory : 
                    v.key === FREE_MOMORY ? listTempFirebase[i].freeMemory : 
                    v.key === FIRMWARE_DEVICE ? listTempFirebase[i].appInfo?.rsDevice : 
                    v.key === FIRMWARE_CAMERA ? listTempFirebase[i].appInfo?.rsFirmware : 
                    v.key === LAST_ONLINE_TIME ? convertDateTime(listTempFirebase[i].time, "HH:mm - DD/MM/YYYY") : ""
                }),{})
                listExport.push(temp)
            }
        }else{
            const temp = data.OptionSelectCheckboxExportDevice.filter((item) => item.isSelect === true).reduce((a, v) => ({...a, [v.name] : ""
            }),{})
            listExport.push(temp)
        }
        
        exportExcel(listExport, 'device')
    }

    const handleClickResetCheckbox = () => {
        setData({
            OptionSelectCheckboxExportDevice: dataSelectCheckBoxExportDevice
        })
    }
    useOnClickOutside(filterRef, () => onClickClosePopup())

  return (
    <WrapPopupExportFileV2 ref={filterRef}>
        <div className="popup-content">
                <div className="icon-close" onClick={onClickClosePopup}>
                    <IcClose />
                </div>
                <div className="content-title">
                    <div className="title">Xuất file</div>
                    <div className="value">Bạn vui lòng chọn dữ liệu muốn xuất file</div>
                </div>

                {/* <div className="content-date-time">
                    <IcCalendarActive />
                    <div className="date-title">Thời gian:</div>
                    <DatePickerItem
                    className="input-dropdown"
                    selected={data.begin}
                    onChange={(date) => setData({ begin: date ? dayjs(date).startOf('day').toDate() : undefined })}
                    maxDate={data.end ? data.end : new Date()}
                    textEmpty="Từ ngày"
                    dateFormat="dd/MM/yyyy"
                    showTimeSelect={false}
                    />
                    <IcArrowRight />
                    <DatePickerItem
                    className="input-dropdown "
                    selected={data.end}
                    onChange={(date) => setData({ end: date ? dayjs(date).endOf('day').toDate() : undefined })}
                    minDate={data.begin}
                    maxDate={new Date()}
                    textEmpty="Đến ngày"
                    dateFormat="dd/MM/yyyy"
                    showTimeSelect={false}
                    />
                </div> */}

                <div className="content-checkbox">
                    {
                        data.OptionSelectCheckboxExportDevice.map((item) => (
                            <CheckBoxControl
                            key={item.id}
                            iconCheckBox={iconCheckBox}
                            className="info-check"
                            isChecked={item.isSelect}
                            handleCheck={() => handleSelectCheckbox(item.id)}
                            title={item.name}
                        />
                        ))
                    }
                </div>

                <div className="content-bottom">
                    <Button 
                        iconBtn={<IcCheckBlue />}
                        text='Chọn tất cả'
                        className="bottom-left"
                        onClick={handleClickResetCheckbox}
                    />
                    <Button
                        iconBtn={<IcExportFileWhite />}
                        className="bottom-right"
                        text='Xuất file Excel'
                        onClick={handleExportFileDevice}
                    />
                </div>
            </div>
    </WrapPopupExportFileV2>
  )
}

export default PopupExportFileDeviceV2