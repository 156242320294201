import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { LOADING_GET_LIST_CUSTOMER } from '../../../redux/customer/listCustomer/types'

//styles
import * as styles from './index.styles'

//constant
import { envServer } from '../../../constant/environmentTypes'
import { LOADING_GET_LIST_DEVICE_CUSTOMER, LOADING_GET_LIST_EKYC_CUSTOMER, LOADING_GET_LIST_VERIFY_CUSTOMER } from '../../../redux/customer/customerDetail/types'
import { LOADING_GET_LIST_TRANSACTION_OF_CUSTOMER } from '../../../redux/customer/customerTransactionHistory/types'
import { LOADING_GET_LIST_BACKLOG_CUSTOMER } from '../../../redux/customer/customerBacklog/types'


export default function TabListMenu(props) {
    const { data, componentSelected } = props
    
    const [keySelected, setKeySelected] = useState(data[0].key)
    
    const { isTypeLoading, isTypeLoadingVerify, isTypeLoadingEkyc, isTypeLoadingTransaction, isTypeLoadingBacklog, isTypeLoadingDevice } = useSelector((state) => ({
        isTypeLoading: state.loading[LOADING_GET_LIST_CUSTOMER],
        isTypeLoadingVerify: state.loading[LOADING_GET_LIST_VERIFY_CUSTOMER],
        isTypeLoadingDevice: state.loading[LOADING_GET_LIST_DEVICE_CUSTOMER],
        isTypeLoadingEkyc: state.loading[LOADING_GET_LIST_EKYC_CUSTOMER],
        isTypeLoadingTransaction: state.loading[LOADING_GET_LIST_TRANSACTION_OF_CUSTOMER],
        isTypeLoadingBacklog: state.loading[LOADING_GET_LIST_BACKLOG_CUSTOMER],
    }))
    
    useEffect(() => {
        if (!data || data.length === 0) return
        for (let i = 0; i < data.length; i++) {
            const item = data[i];
            if(item.envServerValid.includes(envServer)){
                _handleSelect(item)
                break
            }
        }

        return () => {

        }
    }, [data])

    const _handleSelect = (item) => {
        if (!item) return       
        if(isTypeLoading || isTypeLoadingVerify || isTypeLoadingDevice || isTypeLoadingEkyc || isTypeLoadingTransaction || isTypeLoadingBacklog) return
        setKeySelected(item.key)
        componentSelected && componentSelected(item.component)
    }

    return (
        <styles.Bound>
            {
                data.map((item, i) => {
                    return (
                        item.envServerValid.includes(envServer) &&
                        <div key={i} className={`tab ${item.key === keySelected ? 'selected' : ''}`}
                            onClick={() => _handleSelect(item)} >
                            {item.isAlert && <div className='alert-not'/>}
                            {item.title}
                        </div>
                    )
                })
            }           
        </styles.Bound>
    )
}
