import moment from 'moment';
import React, { useReducer, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';

// styled
import { WrapPopupExportFileV2 } from '../index.styles'

// images
import IcArrowRight from '../../../../../../assets/images/IcArrowRight';
import IcCalendarActive from '../../../../../../assets/images/IcCalendarActive';
import IcCheckBlue from '../../../../../../assets/images/IcCheckBlue';
import IcCheckBoxActive from '../../../../../../assets/images/IcCheckBoxActive';
import IcExportFileWhite from '../../../../../../assets/images/IcExportFileWhite';
import IcClose from '../../../../../../assets/images/IcClose';
import IcUnCheckBox from '../../../../../../assets/images/IcUnCheckBox';

// component
import Button from '../../../../../Control/Button';
import CheckBoxControl from '../../../../../Control/CheckBoxControl';
import DatePickerItem from '../../../../../Control/DatePickerItem';
 
// hook
import { useOnClickOutside } from '../../../../../../hook/useClickOutside';

// redux 
import { exportListTransactionBank } from '../../../../../../redux/transactionV2/listTransactionBank/action';



export const iconCheckBox = {
    icon: IcUnCheckBox,
    iconActive: IcCheckBoxActive,
};
  

const ExportFileBankV2 = ({ onClickClosePopup }) => {

    const filterRef = useRef()
    const dispatch = useDispatch()

    const { condFiltersExportTransactionBank } = useSelector((state) => ({
        condFiltersExportTransactionBank: state.transactionReducer.listTransactionBankReducer.condFiltersExportTransactionBank,
    }))

    const [data, setData] = useReducer(
        (state, action) => ({...state, ...action}), 
        {
            isFacepay: condFiltersExportTransactionBank.isFacepay,
            begin: condFiltersExportTransactionBank.begin,
            end: condFiltersExportTransactionBank.end,
            OptionSelectCheckboxExportBank: condFiltersExportTransactionBank.OptionSelectCheckboxExportBank
        }
    )

    const handleSelectCheckbox = (checkboxSelected) => {
        if(!data.OptionSelectCheckboxExportBank) return
        
        let checkboxCollection = data.OptionSelectCheckboxExportBank.map((item) => {
            if(item.id !== checkboxSelected){
                return {
                    ...item
                }
            }else {
                return {
                    ...item,
                    isSelect: !item.isSelect
                }
            }
        })
        setData({
            OptionSelectCheckboxExportBank: checkboxCollection
        })
    }

    const handleClickResetCheckbox = () => {
        setData({OptionSelectCheckboxExportBank: condFiltersExportTransactionBank.OptionSelectCheckboxExportBank})
    }

    const handleExport = () => {
        
        const checkIsStatusCheckbox = () => {
            let dataRequest = data.OptionSelectCheckboxExportBank.reduce((a, v) => ({ ...a, [v.title]: v.isSelect}), {})
            return {...dataRequest, begin: data.begin, end: data.end, isFacepay: data.isFacepay}
        } 

        dispatch(exportListTransactionBank(checkIsStatusCheckbox()))
        onClickClosePopup()
    }

   useOnClickOutside(filterRef, () => onClickClosePopup())
    
    return (
        <WrapPopupExportFileV2 ref={filterRef}>
            <div className="popup-content">
                <div className="icon-close" onClick={onClickClosePopup}>
                    <IcClose />
                </div>
                <div className="content-title">
                    <div className="title">Xuất file</div>
                    <div className="value">Bạn vui lòng chọn dữ liệu muốn xuất file</div>
                </div>

                <div className="content-date-time">
                    <IcCalendarActive />
                    <div className="date-title">Thời gian:</div>
                    <DatePickerItem
                    className="input-dropdown"
                    selected={data.begin}
                    onChange={(date) => setData({ begin: date ? moment(date).startOf('day').toDate() : undefined })}
                    maxDate={data.end ? data.end : new Date()}
                    textEmpty="Từ ngày"
                    dateFormat="dd/MM/yyyy"
                    showTimeSelect={false}
                    />
                    <IcArrowRight />
                    <DatePickerItem
                    className="input-dropdown "
                    selected={data.end}
                    onChange={(date) => setData({ end: date ? moment(date).endOf('day').toDate() : undefined })}
                    minDate={data.begin}
                    maxDate={new Date()}
                    textEmpty="Đến ngày"
                    dateFormat="dd/MM/yyyy"
                    showTimeSelect={false}
                    />
                </div>

                <div className="content-checkbox">
                    {
                        data.OptionSelectCheckboxExportBank.map((item, index) => (
                            <CheckBoxControl
                            key={index}
                            iconCheckBox={iconCheckBox}
                            className="info-check"
                            isChecked={item.isSelect}
                            handleCheck={() => handleSelectCheckbox(item.id)}
                            title={item.name}
                        />
                        ))
                    }
                </div>

                <div className="content-bottom">
                    <Button 
                        iconBtn={<IcCheckBlue />}
                        text='Chọn tất cả'
                        className="bottom-left"
                        onClick={handleClickResetCheckbox}
                    />
                    <Button
                        iconBtn={<IcExportFileWhite />}
                        className="bottom-right"
                        text='Xuất file Excel'
                        onClick={handleExport}
                    />
                </div>
            </div>
        </WrapPopupExportFileV2>
    )
}

export default ExportFileBankV2
