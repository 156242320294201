// lỗi TPB bank trả về
export const dataErrorOCB = {
    ["06"]: {text: "Tài khoản bị khóa từ hệ thống CSKH Facepay"},
    ["10"]: {text: "Khuôn mặt khách hàng chưa được đăng ký Face OTP."},
    ["21"]: {text: "Kết nối OCB thất bại, hệ thống không nhận dạng được khuôn mặt, khách hàng cần thử lại"},
    ["43"]: {text: "Chưa kích hoạt dịch vụ FaceOTP"},
    ["105101"]: {text: "Mã giao dịch của đối tác đã tồn tại"},
    ["105131"]: {text: "Kết nối OCB thất bại do lỗi hệ thống ngân hàng"},
    ["105151"]: {text: "Kết nối OCB thất bại do lỗi hệ thống ngân hàng"},
    ["105132"]: {text: "Kết nối OCB thất bại do giao dịch chưa hoàn tất đối soát từ ngân hàng"},
    ["105133"]: {text: "Kết nối OCB thất bại do lỗi hệ thống ngân hàng"},
    ["105135"]: {text: "Kết nối OCB thất bại do lỗi hệ thống ngân hàng"},
    ["105136"]: {text: "Tài khoản nguồn OCB không thể sử dụng."},
    ["105137"]: {text: "Số tiền giao dịch vượt quá hạn mức 5,000,000đ/ ngày"},
    ["105138"]: {text: "Số tiền giao dịch vượt quá hạn mức 2,000,000đ/ giao dịch"},
    ["105139"]: {text: "Tài khoản ebanking không hợp lệ do số dư không khả dụng"},
    ["105141"]: {text: "tài khoản thanh toán không hợp lệ do lỗi phát sinh từ OCB"},
    ["105142"]: {text: "tài khoản thanh toán không hợp lệ do lỗi phát sinh từ OCB"},
    ["105143"]: {text: "tài khoản thanh toán không hợp lệ do lỗi phát sinh từ OCB"},
    ["105144"]: {text: "Số điện thoại không trùng với số điện thoại đăng ký"},
    ["105145"]: {text: "Giao dịch thất bại do lỗi hệ thống ngân hàng"},
    ["105146"]: {text: "Giao dịch có lỗi phát sinh từ hệ thống ngân hàng ocb"},
    ["105147"]: {text: "Giao dịch có lỗi phát sinh từ hệ thống ngân hàng ocb"},
    ["105148"]: {text: "Kết nối Facepay thất bại do lỗi hệ thống ngân hàng"},
    ["105149"]: {text: "Kết nối Facepay thất bại do lỗi hệ thống ngân hàng"},
    ["105152"]: {text: "Kết nối Facepay thất bại do chưa kích hoạt face otp"},
    ["105153"]: {text: "Gói dịch vụ OCB OMNI không hợp lệ. KH cần nâng cấp gói với OCB trước khi thanh toán hoặc liên hệ tổng đài OCB"},
    ["105155"]: {text: "Số tiền giao dịch vượt quá hạn mức 2,000,000đ/ giao dịch"},
    ["-99"]: {text: "Giao dịch thất bại do lỗi hệ thống ngân hàng"},
    ["-100"]: {text: "Giao dịch không thành công do chưa hoàn tất."},
    ["-101"]: {text: "Giao dịch thất bại do lỗi hệ thống ngân hàng"},
    ["-102"]: {text: "Khách hàng chưa nhập đúng số điện thoại"},
    ["-103"]: {text: "Khách hàng cancel giao dịch do không được áp dụng khuyến mãi"},


}