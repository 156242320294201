import React, { useEffect, useRef, useState } from "react";

//style
import { WrapTransHistoryContent, WrapTransHistoryItem } from "./index.styles";

//help
import { convertDateTime } from "../../../../../../../../helper/convertDateTime";
import numberFormatToThousand from "../../../../../../../../helper/numberFormatToThousand";

//image
import IcCheckBgBlue from "../../../../../../../../assets/images/IcCheckBgBlue";
import IcCloseRedMini from "../../../../../../../../assets/images/IcCloseRedMini";
import IcNoteBlue from "../../../../../../../../assets/images/IcNoteBlue";
import TransHistoryDetailsItem from "./TransHistoryDetailsItem";
import PopupAttachNote from "../PopupAttachNote";
import Portal from "../../../../../../../Control/Portal";
import { POPUP_NOTE_TRANSACTION_CUSTOMER } from "../../../../../../../../constant/keys";
import { useDispatch, useSelector } from "react-redux";
import { hidePopupNote, showPopupNote } from "../../../../../../../../redux/customer/customerNote/action";
import { CUSTOMER } from "../../../../../../../../constant/path";

export const SUCCESS = "SUCCESS";
export const FAIL = "FAIL";

const initialPos = {
  top: "0%",
  left: "100%",
  right: "initial",
  bottom: "initial",
};
const DETAILS = "DETAILS";
const NOTE = "NOTE";

const TransHistoryItem = ({ data, isShowPopup, setIsShowPopup, listRef }) => {
  const {
    facepayTransCode,
    status,
    storeName,
    storeAddress,
    createdAt,
    transAmount,
    isWallet
  } = data;

  const  dispatch = useDispatch()
  const wrapPopupRef = useRef(null);
  const wrapItemRef = useRef(null);

  const refNote = useRef(null)
  const refPopup = useRef(null)
  const [isOutList, setIsOutList] = useState('')

  const [isKeyFrom, setIsKeyFrom] = useState({})

  const [isEnter, setIsEnter] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [posPopup, setPosPopup] = useState({
    ...initialPos,
  });

  const { details, reqType, isShowPopupNote } = useSelector((state) => ({
    details: state.customerReducer.customerDetailReducer.details,
    reqType: state.customerReducer.listCustomerReducer.reqType,
    isShowPopupNote: state.customerReducer.customerNoteReducer.isShowPopupNote
  }))

  const _handleOpenPopupNote = (e) => {
    e.stopPropagation();
    dispatch(showPopupNote())
    setIsShowPopup(data.facepayTransCode)
    setIsKeyFrom(NOTE)
  };

  const _handleOpenPopupDetail = () => {
    if(isWallet) return
    dispatch(hidePopupNote())
    setIsShowPopup(data.facepayTransCode)
    setIsKeyFrom(DETAILS)
  };

  useEffect(() => {
    if (listRef && isKeyFrom === NOTE) {
      const listRect = listRef.current.getBoundingClientRect() // ref list
      const { height, top } = listRect
      const noteRect = refNote.current.getBoundingClientRect()
      const PopupRect = refPopup.current.getBoundingClientRect()// ref popup
      const hightY = height + top
      const isOutY = PopupRect.top + PopupRect.height
      if (isOutY > hightY) {
        let newTopPopup = noteRect.bottom - 184
        if (newTopPopup < top) {
          let delta = top - newTopPopup
          setIsOutList(`${delta}%`)
        }else{
          setIsOutList("0px")
        }
      }
    } else {
      setIsOutList('')

    }
  }, [listRef, isKeyFrom])


  const handleMouseEnter = () => {
    setIsEnter(true);
  };

  const handleMouseLeave = () => {
    setIsEnter(false);
    setIsVisible(false);
    setPosPopup({
      ...initialPos,
    });
  };

  const _onClickClosePopup = () => {
    dispatch(hidePopupNote())
    setIsKeyFrom("")
    setIsShowPopup("")
  }

  useEffect(() => {
    _onClickClosePopup()
  },[details?.id, reqType])
  
  useEffect(() => {
    if(isShowPopupNote && isKeyFrom === NOTE){
      listRef.current?.addEventListener('scroll', _onClickClosePopup)
    }
    return () => {
      listRef.current?.removeEventListener('scroll', _onClickClosePopup)
    }
  },[isShowPopupNote])

  return (
    <WrapTransHistoryContent
      ref={refNote}
      propsPopup={isOutList}
    >
      <WrapTransHistoryItem
        ref={wrapItemRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        posPopup={posPopup}
        isVisible={isVisible}
        onClick={_handleOpenPopupDetail}
        // className={data.facepayTransCode === isShowPopup ? "active" : ""}
      >
        <div className="wrap-content" >
          <div className="content">
            {status === 1 ? (
              <IcCheckBgBlue className="icon" />
            ) : (
                <IcCloseRedMini className="icon" />
              )}
            <p className="value bold">{facepayTransCode || "-"}</p>
          </div>
          <div className="value">
            {createdAt ? convertDateTime(createdAt, "HH:mm - DD/MM/YYYY") : "-"}
          </div>
        </div>
        <div className="wrap-content right" >
          <div className="content">
            <p className="value bold">
              {transAmount && numberFormatToThousand(transAmount)}
            </p>
            <IcNoteBlue
              className="icon-note"
              onClick={(e) => {
                _handleOpenPopupNote(e)
              }}
            />
          </div>
          <p className="value">{storeName || "-"}</p>
        </div>
      </WrapTransHistoryItem>
      {
        data.facepayTransCode === isShowPopup && isKeyFrom === NOTE && isShowPopupNote && (
          <div className="wrap-content-popup" ref={refPopup}>
            <PopupAttachNote
              isStylePopup={false}
              isShowContentPopup={POPUP_NOTE_TRANSACTION_CUSTOMER}
              data={data}
              onClickClosePopup={_onClickClosePopup}
            />
          </div>
        )
      }
      {
        data.facepayTransCode === isShowPopup && isKeyFrom === DETAILS && (
          <Portal>
            <TransHistoryDetailsItem
              data={data}
              onClickClosePopup={_onClickClosePopup}
              detailsTrans={CUSTOMER}
            />
          </Portal>
        )
      }
    </WrapTransHistoryContent>
  );
};

export default TransHistoryItem;
