import React, { useRef } from 'react'

// style
import { WrapDropdownListFeatureDevice } from './index.styles'

// data
import { dataFeatureDevice } from '../../../../../../data/dataDeviceFeature'
import { useOnClickOutside } from '../../../../../../hook/useClickOutside'
import HandleCallApiLuancherAction from '../HandleCallApiLuancherAction'

const DropdownFeatureDevidce = ({ onCloseDropdown, data, checkShowFeatureDevice }) => {

    const dropdownRef = useRef(null)

    useOnClickOutside(dropdownRef, () => onCloseDropdown())

    return (
        <WrapDropdownListFeatureDevice ref={dropdownRef}>
            {
                dataFeatureDevice.map((item) => 
                    (
                        checkShowFeatureDevice ? (
                            <div className="feature-item" key={item.id} onClick={HandleCallApiLuancherAction(item.key, [data.serialNumber])}>
                            {item.isDisable ? item.iconDisable : item.icon}
                            <span className={item.isDisable ? "disable" : ""}>{item.text}</span>
                        </div>
                        ) : (
                            <div className={`feature-item ${!item.isCheckShowFeatureDevice ? 'hide-feature' : ""}`} key={item.id} onClick={HandleCallApiLuancherAction(item.key, data)}>
                            {item.isDisable ? item.iconDisable : item.icon}
                            <span className={item.isDisable ? "disable" : ""}>{item.text}</span>
                        </div>
                        )
                    )
                )
            }
        </WrapDropdownListFeatureDevice>
    )
}

export default DropdownFeatureDevidce
