import React, { useState, useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";

import { host } from "../../../../../../../constant/host";

// css
import { WrapPopupRejectCensor } from "./index.styles";

// img
import IcClose from "../../../../../../../assets/images/IcClose";
import IcCloseRed from "../../../../../../../assets/images/IcCloseRed";
import IcCloseOutlineWhite from "../../../../../../../assets/images/IcCloseOutlineWhite";
import IcRejectOther from "../../../../../../../assets/images/IcRejectOther";

// component
import Button from "../../../../../../Control/Button";
import TextArea from "../../../../../../Control/TextArea";

// data
// import dataRejectIconDefault from "../../../../../../../data/dataReject";

const PopupRejectCensor = ({ reason, onClickClosePopup, _handleSubmit, rect }) => {

  const { censorshipId } = useParams()

  const [list, setList] = useState([]);
  const [otherReason, setOtherReason] = useReducer(
    (state, action) => ({ ...state, ...action }),
    {
      Reason: "Lý do khác",
      url: "reason_6",
      iconDefault: <IcRejectOther />,
      isActive: false,
      valueTextArea: ""
    }
  );

  const [isNotice, setIsNotice] = useState(false);
  useEffect(() => {
    // const listReason = ([...reason, {Reason: "Lý do khác", url: "reason_6", iconDefault: <IcRejectOther /> }])
    const listReason = reason.map(item => {
      const reason = {
        ...item,
        isActive: false
      }
      return reason
    })
    setList(listReason)
  }, [])

  const totalReject = list.reduce((total, item) => {
    return item.isActive === true ? total + 1 : total;
  }, 0);


  const resetData = () => {
    let listDefault = [...list]
    listDefault = list.filter((item) => { return item.isActive = false })
    return listDefault
  }

  const handleSubmit = () => {
    let listCheck = list.filter((item) => {
      return item.isActive
    }).map((item) => item.Reason)
    if (!otherReason.isActive) {
      _handleSubmit(censorshipId, listCheck, otherReason.valueTextArea);
      return;
    }
    if (otherReason.valueTextArea && otherReason.valueTextArea.length > 0) {
      _handleSubmit(censorshipId, listCheck, otherReason.valueTextArea)
      return
    }
    setIsNotice(true)
  };

  const handleClose = () => {
    onClickClosePopup()
    setList(resetData())
  }

  const handleChange = (e) => {
    setOtherReason({ valueTextArea: e.target.value })
    setIsNotice(false);
  };

  const handleClick = (index) => {
    const listTemp = [...list];
    listTemp[index].isActive = !listTemp[index].isActive;
    setList(listTemp);
    setIsNotice(false);
  };

  useEffect(() => {
    if(!otherReason.isActive){
      setOtherReason({ valueTextArea : "" })
    }
  },[otherReason.isActive])
  // console.log(rect)

  return (
    <WrapPopupRejectCensor rect={rect}>
      <div className="popup-header">
        <div className="title">
          <p className="title-value">Từ chối hồ sơ</p>
          <div className="icon-close" onClick={handleClose}>
            <IcClose />
          </div>
        </div>
        <div className="value">Bạn vui lòng xác nhận lý do từ chối:</div>
      </div>
      <div className="popup-content">
        {list?.map((item, index) => (
          <div className="content-item" key={index}>
            <div
              className="content-icon"
              onClick={() => {
                handleClick(index);
              }}
            >
              {!item.isActive ? (
                <>
                  {!item.iconDefault ? <img src={`${host}${item.url}`} /> : item.iconDefault}
                </>
              ) : <IcCloseRed />}
            </div>
            <div className="content-value">{item.Reason}</div>
          </div>
        ))}
        <div className="content-item">
          <div
            className="content-icon"
            onClick={() => {
              setOtherReason({ isActive: !otherReason.isActive });
            }}
          >
            {!otherReason.isActive ? (
              <>
                {!otherReason.iconDefault ? <img src={`${host}${otherReason.url}`} /> : otherReason.iconDefault}
              </>
            ) : <IcCloseRed />}
          </div>
          <div className="content-value">{otherReason.Reason}</div>
        </div>
        {otherReason?.isActive === true && (
          <div className="wrap-reasion">
            <TextArea
              placeholder="Nhập nội dung..."
              onChange={handleChange}
              value={otherReason.valueTextArea}
              maxLength={120}
            />
            {isNotice && (
              <div className="reason-notice">
                Không được để trống thông tin này
              </div>
            )}
          </div>
        )}
      </div>
      <div className="popup-bottom">
        <div className="bottom-left">
          <div className="bottom-number">
            <IcCloseRed className="icon-close" />
            <p>{otherReason.isActive === true ? totalReject + 1 : totalReject} / 6</p>
          </div>
          <div className="bottom-value">{otherReason.isActive === true ? totalReject + 1 : totalReject} Lý do từ chối</div>
        </div>
        <Button
          iconBtn={totalReject || (otherReason.isActive === true && otherReason.valueTextArea.length > 0) ? <IcCloseOutlineWhite /> : ""}
          type="submit"
          text="Từ chối"
          className={`btn-reject ${totalReject !== 0 || (otherReason.isActive === true && otherReason.valueTextArea.length > 0) ? "active" : ""}`}
          isDisable={otherReason.isActive || totalReject > 0 ?
            totalReject > 0 || (otherReason.isActive && otherReason.valueTextArea.length > 0) ? false : true
            : otherReason.valueTextArea.length > 0 ? false : true}
          onClick={handleSubmit}
        />
      </div>
    </WrapPopupRejectCensor>
  );
};

export default PopupRejectCensor;
