import React, { useEffect, useMemo, useReducer, useState, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux'

//styles
import { WrapFilterBankBlock, WrapFilterSearch, WrapDropDown } from './index.styles'

//image
import IcUnCheckBox from "../../../../../../../../assets/images/IcUnCheckBox";
import IcCheckBoxActive from "../../../../../../../../assets/images/IcCheckBoxActive";
import IcExportFileBlue from "../../../../../../../../assets/images/IcExportFileBlue";

//helper
import removeAccent from "../../../../../../../../helper/removeAccent";
import trimStartAndStripStr from "../../../../../../../../helper/trimStartAndStripStr";
// import DropdownFilterCustomer, { FULLNAME_ID, PROFILE_ID, PHONE_NUMBER } from "./DropdownFilterCustomer";

//action
import { updateBankCustomerCondsFilter } from "../../../../../../../../redux/customer/listCustomer/actionV2";

//component
// import { InputFilter } from "../../../../../../../Control/FilterControl/index.styles";
// import FilterControl from "../../../../../../../Control/FilterControl";
// import CheckBoxControl from "../../../../../../../Control/CheckBoxControl";
import InputDropdownBank from "./InputDropDownBank";
import InputSearch from "../../../../../../../Control/InputSearch";
import { listProviders } from "../../../../../../../../redux/customer/listCustomer/listCustomerReducerV2";
import { LOADING_GET_LIST_CUSTOMER } from "../../../../../../../../redux/customer/listCustomer/types";
import resetListFilterBank from "../../../../../../../../helper/resetListFilterBank";

const iconCheckBox = {
  icon: IcUnCheckBox,
  iconActive: IcCheckBoxActive,
};

export default function FilterBankBlock() {

  const dispatch = useDispatch()
  // const inputSearchRef = useRef(null)

  const { condFiltersBank, isCheckList, listCustomerBank, isTypeLoading } = useSelector(state => ({
    condFiltersBank: state.customerReducer.listCustomerReducer.condFiltersBank,
    listCustomerBank: state.customerReducer.listCustomerReducer.listCustomerBank,

    isCheckList: state.customerReducer.listCustomerReducer.isCheckList,

    isTypeLoading: state.loading[LOADING_GET_LIST_CUSTOMER]

  }))
  
  const [listSelect, setListSelect] = useState(listProviders)
  const [arrProviders, setArrProviders] = useState([])
  const [valueSearch, setValueSearch] = useState(condFiltersBank.phone || "");

  const _handleSearch = () => {
    dispatch(updateBankCustomerCondsFilter({
      condFiltersBank: {
        limit: 15,
        skipItem: 0,
        phone: valueSearch,
        providers: condFiltersBank.providers
      }
    }))
  }

  const _handleSelectCheckbox = (index) => {
    let listTemp = [...listSelect];
    if (isTypeLoading) return
    listTemp[index].isSelect = !listTemp[index].isSelect;
    setListSelect(listTemp)

  }

  useEffect(() => {
    let listCheckBox = listSelect
      .filter((item) => { return item.isSelect })
      .map((item) => { return item.type })
    setArrProviders(listCheckBox)
    dispatch(updateBankCustomerCondsFilter({
      condFiltersBank: {
        skipItem: 0,
        providers: listCheckBox
      },
    }))
  }, [listSelect])

  useEffect(() => {
    if (listCustomerBank.length === 0 && !isCheckList) {
      setValueSearch('')
      setArrProviders(resetListFilterBank(listProviders))
    }
  }, [listCustomerBank])

  return (
    <WrapFilterBankBlock>
      <WrapFilterSearch>
        <InputSearch
          className="filter-search"
          value={valueSearch}
          type="text"
          placeholder="Nhập số điện thoại..."
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              _handleSearch();
            }
          }}
          maxLength={10}
          handleSearch={_handleSearch}
          onChange={(e) => setValueSearch(removeAccent(trimStartAndStripStr(e.target.value)))}
        />
        {/* <div title="Xuất file dữ liệu">
          <IcExportFileBlue className="icon-export" />
        </div> */}
      </WrapFilterSearch>
      <WrapDropDown>
        <InputDropdownBank
          iconCheckBox={iconCheckBox}
          listDropdown={listProviders}
          listChecked={arrProviders}
          handleCheckItem={_handleSelectCheckbox}
        />
      </WrapDropDown>
    </WrapFilterBankBlock>
  )
}
