import React from 'react'

//const
import { ANALYTIC, MAIN, TRANSACTION, DEVICE, MERCHANT, PAYMENT_TERMINAL, BANK, SETTING, CUSTOMER, PERMISSION, CENSORSHIP } from "../constant/path";
import { WEE_ENV, BANK_ENV, DEV_ENV,
        DEV_SV, QC_SV, PROD_SV, SBX_SV } from '../constant/environmentTypes';

//image
import IcAnalytic from '../assets/images/IcAnalytic';
import IcAnalyticActive from '../assets/images/IcAnalyticActive';
import IcTransaction from '../assets/images/IcTransaction';
import IcTransactionActive from '../assets/images/IcTransactionActive';
import IcDevices from '../assets/images/IcDevices';
import IcDevicesActive from '../assets/images/IcDevicesActive';
import IcMerchant from '../assets/images/IcMerchant';
import IcMerchantAcive from '../assets/images/IcMerchantActive'
import IcBanks from '../assets/images/IcBanks';
import IcBanksActive from '../assets/images/IcBanksActive';
import IcSettings from '../assets/images/IcSettings';
import IcSettingsActive from '../assets/images/IcSettingsActive';
import IcUsers from '../assets/images/IcUsers';
import IcUsersActive from '../assets/images/IcUsersActive';
import IcPermissions from '../assets/images/IcPermissions';
import IcPermissionsActive from '../assets/images/IcPermissionsActive';
import IcUserCheck from '../assets/images/IcUserCheck';
import IcUserCheckActive from '../assets/images/IcUserCheckActive';

const dataMenu = [
    {
        key: ANALYTIC,
        path: `/${MAIN}/${ANALYTIC}`,
        Icon: IcAnalytic,
        IconActive: IcAnalyticActive,
        name: 'Phân tích dữ liệu',
        envValid: [
            DEV_ENV, 
            // WEE_ENV, 
            BANK_ENV
        ],
        envServerValid: [
            // DEV_SV
        ]
    },
    {
        key: TRANSACTION,
        path: `/${MAIN}/${TRANSACTION}`,
        Icon: IcTransaction,
        IconActive: IcTransactionActive,
        name: 'Giao dịch',
        envValid: [
            DEV_ENV, 
            WEE_ENV, 
            BANK_ENV
        ],
        envServerValid: [
            DEV_SV,
            QC_SV,
            SBX_SV,
            PROD_SV
        ]
    },
    {
        key: DEVICE,
        path: `/${MAIN}/${DEVICE}`,
        Icon: IcDevices,
        IconActive: IcDevicesActive,
        name: 'Thiết bị',
        envValid: [
            DEV_ENV, 
            WEE_ENV
        ],
        envServerValid: [
            DEV_SV,
            QC_SV,
            SBX_SV,
            PROD_SV
        ]
    },
    {
        key: PAYMENT_TERMINAL,
        path: `/${MAIN}/${PAYMENT_TERMINAL}`,
        Icon: IcDevices,
        IconActive: IcDevicesActive,
        name: 'Payment Terminal',
        envValid: [
            DEV_ENV
        ],
        envServerValid: [
            
        ]
    },
    {
        key: CUSTOMER,
        path: `/${MAIN}/${CUSTOMER}`,
        Icon: IcUsers,
        IconActive: IcUsersActive,
        name: 'Khách hàng',
        envValid: [
            DEV_ENV, 
            WEE_ENV
        ],
        envServerValid: [
            DEV_SV,
            QC_SV,
            SBX_SV,
            PROD_SV
        ]
    },
    {
        key: MERCHANT,
        path: `/${MAIN}/${MERCHANT}`,
        Icon: IcMerchant,
        IconActive: IcMerchantAcive,
        name: 'Merchants',
        envValid: [
            DEV_ENV, 
            // WEE_ENV
        ],
        envServerValid: [
            // DEV_SV
        ]
    },
    {
        key: BANK,
        path: `/${MAIN}/${BANK}`,
        Icon: IcBanks,
        IconActive: IcBanksActive,
        name: 'Ngân hàng',
        envValid: [
            DEV_ENV, 
            // WEE_ENV
        ],
        envServerValid: [
            // DEV_SV
        ]
    },
    {
        key: PERMISSION,
        path: `/${MAIN}/${PERMISSION}`,
        Icon: IcUsers,
        IconActive: IcPermissionsActive,
        name: 'Permissions',
        envValid: [
            DEV_ENV
        ],
        envServerValid: [
            
        ]
    },
    {
        key: CENSORSHIP,
        path: `/${MAIN}/${CENSORSHIP}`,
        Icon: IcUserCheck,
        IconActive: IcUserCheckActive,
        name: 'Kiểm duyệt tài khoản',
        envValid: [
            DEV_ENV, 
            WEE_ENV
        ],
        envServerValid: [
            DEV_SV,
            QC_SV,
            SBX_SV,
            PROD_SV
        ]
    },
]

export default dataMenu