import React, { useMemo, useReducer, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

//style
import { WrapFilterWaitingCensorship, FilterControlTime, FilterCheckBox } from "./index.styles";

//component
import FilterControl from "../../../../../../Control/FilterControl";
import { InputFilter } from "../../../../../../Control/FilterControl/index.styles";
import DropdownSelectSearchConsorship, {
  DOCUMENT_ID,
  PHONE_NUMBER,
} from "./DropdownCensorship";
import DatePickerItem from "../../../../../../Control/DatePickerItem";
import CheckBoxControl from '../../../../../../Control/CheckBoxControl'

//image
import IcArrowRight from "../../../../../../../assets/images/IcArrowRight";
import IcCalendarActive from "../../../../../../../assets/images/IcCalendarActive";
import IcCheckBoxActive from "../../../../../../../assets/images/IcCheckBoxActive";
import IcUnCheckBox from "../../../../../../../assets/images/IcUnCheckBox";

//help
import trimStartAndStripStr from "../../../../../../../helper/trimStartAndStripStr";
import removeAccent from "../../../../../../../helper/removeAccent";

//action
import { resetListWaitingCensorship, updateWaitingCondsFilter } from "../../../../../../../redux/censorship/listWaitingCensorship/action";

const iconCheckBox = {
  icon: IcUnCheckBox,
  iconActive: IcCheckBoxActive,
};

const FilterWaitingCensorship = () => {

  const dispatch = useDispatch()

  const inputSearchRef = useRef(null)

  const { condFilters } = useSelector(state => ({
    condFilters: state.censorshipReducer.listWaitingCensorshipReducer.condFilters,
  }))

  const [filterCond, setFilterCond] = useState(condFilters.optionSelected);
  const [valueSearch, setValueSearch] = useState(
    condFilters.optionSelected.key === DOCUMENT_ID ? condFilters.documentId
      :
      condFilters.optionSelected.key === PHONE_NUMBER ? condFilters.phone : ""
  );  

  const [data, setData] = useReducer(
    (state, action) => ({ ...state, ...action }),
    {
      phone: condFilters.phone,
      documentId: condFilters.documentId,
      startTime: condFilters.startTime,
      endTime: condFilters.endTime,
      isNew: condFilters.isNew,
      isRepending: condFilters.isRepending,
    }
  );
  
  useEffect(() => {
    dispatch(updateWaitingCondsFilter({
      condFilters: {
        optionSelected: condFilters.optionSelected,
        lastItem: "",
        phone: condFilters.phone,
        documentId: condFilters.documentId,
        startTime: data.startTime,
        endTime: data.endTime,
        isNew: data.isNew,
        isRepending: data.isRepending
      }
    }
    ))
    return () => {
      dispatch(resetListWaitingCensorship())
    }
  }, [JSON.stringify(data)])

  const _handleCheckNewRegister = (val) => {
    setData({ isNew: !val });
  };

  const _handleCheckPending = (val) => {
    setData({ isRepending: !val });
  };

  const _handleSearch = () => {
    const { key } = filterCond
    let phone = ""
    let documentId = ""
    switch (key) {
      case PHONE_NUMBER: {
        phone = valueSearch
        break;
      }
      case DOCUMENT_ID: {
        documentId = valueSearch
        break;
      }
      default:
        return;
    }
    dispatch(updateWaitingCondsFilter({
      condFilters: {
        optionSelected: filterCond,
        lastItem: "",
        phone,
        documentId,
        startTime: condFilters.startTime,
        endTime: condFilters.endTime,
        isNew: condFilters.isNew,
        isRepending: condFilters.isRepending
      }
    }
    ))
  };

  const _handleChangeOptionFilter = (filterOption) => {
    setFilterCond(filterOption)
    setValueSearch("")
    if (inputSearchRef.current) inputSearchRef.current.value = ''
  }

  const dropdownFilter = useMemo(
    () => (
      <DropdownSelectSearchConsorship
        filterCond={filterCond}
        setFilterCond={_handleChangeOptionFilter}
      />
    ),
    [filterCond, setFilterCond]
  );

  const filterComponent = useMemo(() => {
    const { key } = filterCond;
    switch (key) {
      case PHONE_NUMBER: {
        return (
          <InputFilter
            value={valueSearch}
            type="text"
            placeholder="Nhập số điện thoại"
            ref={inputSearchRef}
            maxLength={10}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                _handleSearch()
              }
            }}
            onChange={(e) => setValueSearch(removeAccent(trimStartAndStripStr(e.target.value)))}
          />
        );
      }
      case DOCUMENT_ID: {
        return (
          <InputFilter
            value={valueSearch}
            type="text"
            placeholder="Nhập Document ID ..."
            ref={inputSearchRef}
            maxLength={20}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                _handleSearch()
              }
            }}
            onChange={(e) => setValueSearch(removeAccent(trimStartAndStripStr(e.target.value)))}
          />
        );
      }
      default:
        return null;
    }
  }, [JSON.stringify(filterCond), _handleSearch]);

  return (
    <WrapFilterWaitingCensorship>
      <FilterControl
        dropdownFilter={dropdownFilter}
        filterComponent={filterComponent}
        handleSearch={_handleSearch}
        isDebounce={false}
      />
      <FilterControlTime>
        <IcCalendarActive />
        <div className="title">Thời gian:</div>
        <DatePickerItem
          className="input-dropdown"
          selected={data.startTime}
          onChange={(date) => setData({ startTime: date ? moment(date).startOf('day').toDate() : undefined })}
          maxDate={data.endTime ? data.endTime : new Date()}
          textEmpty="Từ ngày"
          dateFormat="dd/MM/yyyy"
          showTimeSelect={false}
        />
        <IcArrowRight />
        <DatePickerItem
          className="input-dropdown "
          selected={data.endTime}
          onChange={(date) => setData({ endTime: date ? moment(date).endOf('day').toDate() : undefined })}
          minDate={data.startTime}
          maxDate={new Date()}
          textEmpty="Đến ngày"
          dateFormat="dd/MM/yyyy"
          showTimeSelect={false}
        />
      </FilterControlTime>

      <FilterCheckBox>
        <CheckBoxControl
          extendStyles={{ width: "80px" }}
          iconCheckBox={iconCheckBox}
          className="info-check border-left"
          isChecked={data.isNew}
          handleCheck={() => {
            _handleCheckNewRegister(data.isNew);
          }}
          title="Đăng kí mới"
        />
        <CheckBoxControl
          extendStyles={{ width: "80px" }}
          iconCheckBox={iconCheckBox}
          className="info-check border-right"
          isChecked={data.isRepending}
          handleCheck={() => {
            _handleCheckPending(data.isRepending);
          }}
          title="Chờ duyệt lại"
        />
      </FilterCheckBox>
    </WrapFilterWaitingCensorship>
  );
};

export default FilterWaitingCensorship;
