import IcLinkCard from "../assets/images/IcLinkCard";
import IcLinkCardFailed from "../assets/images/IcLinkCardFailed";
import IcDeposit from "../assets/images/IcDeposit";
import IcDepositFailed from "../assets/images/IcDepositFailed";
import { DEPOSIT, LINK_CARD, ANOTHER_WALLET, TO_BANK, WITH_DRAWAL } from "../constant/keys";

const FAILED = 0
const SUCCESS = 1
const REFUNDED = 2 // transaction is succeeded but it was refunded

const dataWalletFacepay = [
    {
        text: "Liên kết thẻ",
        iconSuccess: <IcLinkCard />,
        iconFailed: <IcLinkCardFailed />,
        key: LINK_CARD
    },
    {
        text: "Nạp tiền",
        iconSuccess: <IcDeposit />,
        iconFailed: <IcDepositFailed />,
        key: DEPOSIT
    },
    // {
    //     text: "Chuyển tiền tới ngân hàng",
    //     iconSuccess: <IcLinkCard />,
    //     iconFailed: <IcLinkCard />,
    //     key: TO_BANK
    // },
    // {
    //     text: "Chuyển tiền tới ví",
    //     iconSuccess: <IcLinkCard />,
    //     iconFailed: <IcLinkCard />,
    //     key: ANOTHER_WALLET
    // },
    // {
    //     text: "Rút tiền",
    //     iconSuccess: <IcLinkCard />,
    //     iconFailed: <IcLinkCard />,
    //     key: WITH_DRAWAL
    // },
]

const getValueWalletFacepay = (type, status) => {
    const iconWallet = dataWalletFacepay.filter((item) => item.key === type).map((item) => {
        return (
            status === SUCCESS ? <div title={item.text} key={item.key}>{item.iconSuccess}</div> : <div title={item.text} key={item.key}>{item.iconFailed}</div>
        )
    })
    const valueWallet = dataWalletFacepay.filter((item) => item.key === type).map((item) => item.text)

    return { iconWallet: iconWallet , valueWallet: valueWallet}
}

export default getValueWalletFacepay