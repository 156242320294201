// lỗi TPB bank trả về
export const dataErrorTPB = {
    ["06"]: {text: "Tài khoản bị khóa từ hệ thống CSKH Facepay"},
    ["10"]: {text: "Khuôn mặt chưa được đăng ký dịch vụ Facepay."},
    ["21"]: {text: "Không tìm được khuôn mặt trùng khớp trong hệ thống"},
    ["40"]: {text: "Giao dịch thất bại do lỗi hệ thống ngân hàng"},
    ["2011"]: {text: "Quá thời hạn giao dịch, liên hệ cashier để thử lại"},
    ["2012"]: {text: "Số dư không khả dụng."},
    ["2013"]: {text: "Dịch vụ Facepay ko khả dụng, cần bật tính năng Facepay trong ứng dụng TPbank để sử dụng"},
    ["2014"]: {text: "Dịch vụ Facepay ko khả dụng, cần bật tính năng Facepay trong ứng dụng TPbank để sử dụng"},
    ["2016"]: {text: "Chưa kích hoạt dịch vụ Facepay"},
    ["1101"]: {text: "Tài khoản TPBank không hợp lệ. Vui lòng liên hệ  tổng đài TPBank."},
    ["1103"]: {text: "Tài khoản TPBank không hợp lệ. Vui lòng liên hệ  tổng đài TPBank."},
    ["1104"]: {text: "Chưa kích hoạt dịch vụ Facepay"},
    ["1106"]: {text: "Sđt không đúng, KH cần thử lại giao dịch"},
    ["1107"]: {text: "Chưa kích hoạt dịch vụ Facepay"},
    ["1108"]: {text: "Chưa kích hoạt dịch vụ Facepay"},
    ["1109"]: {text: "Giao dịch thất bại do lỗi hệ thống ngân hàng"},
   
    ["-99"]: {text: "Giao dịch thất bại do lỗi hệ thống ngân hàng"},
    ["-100"]: {text: "Giao dịch không thành công do chưa hoàn tất."},
    ["-101"]: {text: "Giao dịch thất bại do lỗi hệ thống ngân hàng"},
    ["-102"]: {text: "Khách hàng chưa nhập đúng số điện thoại"},
    ["-103"]: {text: "Khách hàng cancel giao dịch do không được áp dụng khuyến mãi"},


}