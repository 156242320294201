import styled from 'styled-components'

const WrapDeviceAdminByList = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    .header-list{
        margin-top: 0;
    }
    .row {
        grid-template-columns: 20px 100px minmax(70px,130px) minmax(220px,240px) minmax(245px, 1fr) 20px minmax(80px,130px) minmax(110px,130px) 120px 20px;
    }
    .wrap-empty {
        height: 100%;
        display: flex;
        align-items: center;
    }
`;

export {
    WrapDeviceAdminByList
}