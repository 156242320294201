import moment from 'moment'

// constant
import { BANK_ENV, WEE_ENV, env,
    DEV_SV, QC_SV, PROD_SV, SBX_SV } from './environmentTypes';

// export const host = "https://dev.facepay.vn/cloud"
// export const hostMedia = "https://dev.facepay.vn/cloud/media"
// export const host = "https://rtc.wee.vn/cloud"
// export const host = "https://bank.facepay.vn/cloud"
// export const hostMedia = "https://bank.facepay.vn/cloud/media"

// export const apiGetAds = (adsId) => 
//     `${host}/advertisement/download/media?id=${adsId}&time=${moment().unix()}`

// export const apiGetThumbnailAds = (adsId) => 
//     `${host}/advertotalTransHistisement/download/thumbnail?id=${adsId}&time=${moment().unix()}`

export const { hostCustomer, host, hostMedia, hostWebSocket } = (() => {
    switch (env) {
        case BANK_ENV: {
            switch (process.env.REACT_APP_SERVER_ENV) {
                case 'qc':
                    return {
                        host: "https://bank-qc.facepay.vn/cloud",
                        hostMedia: "https://bank-qc.facepay.vn/cloud/media",
                    }
                case 'uat':
                    return {
                        host: "https://bank-uat.facepay.vn/cloud",
                        hostMedia: "https://bank-uat.facepay.vn/cloud/media",
                    }
                case 'prod':
                    return {
                        host: "https://bank.facepay.vn/cloud",
                        hostMedia: "https://bank.facepay.vn/cloud/media",
                    }

                default:
                    return {
                        host: "https://bank-dev.facepay.vn/cloud",
                        hostMedia: "https://bank-dev.facepay.vn/cloud/media",
                    }
            }
        }
        case WEE_ENV:
            switch (process.env.REACT_APP_SERVER_ENV) {
                case QC_SV:
                    return {
                        // hostCustomer: "https://facepay-admin-service-getswbwvgq-as.a.run.app", 
                        hostCustomer: "https://admin-qc.facepay.vn/adminservice",
                        host: "https://admin-qc.facepay.vn/cloud",
                        hostMedia: "https://admin-qc.facepay.vn/cloud/media",
                        hostWebSocket: "wss://admin-qc.facepay.vn/wss/notify/connect",
                    }
                case 'uat':
                    return {
                        host: "https://admin-uat.facepay.vn/cloud",
                        hostMedia: "https://admin-uat.facepay.vn/cloud/media",
                        hostWebSocket: "wss://admin-uat.facepay.vn/wss/notify/connect",
                    }
                case PROD_SV:
                    return {
                        hostCustomer: "https://admin.facepay.vn/adminservice",
                        host: "https://admin.facepay.vn/cloud",
                        hostMedia: "https://admin.facepay.vn/cloud/media",
                        hostWebSocket: "wss://admin.facepay.vn/wss/notify/connect",
                    }
                case SBX_SV:
                    return {
                        hostCustomer: "https://admin-sbx.facepay.vn/adminservice",
                        host: "https://admin-sbx.facepay.vn/cloud",
                        hostMedia: "https://admin-sbx.facepay.vn/cloud/media",
                        hostWebSocket: "wss://admin-sbx.facepay.vn/wss/notify/connect",
                    }
                default:
                    return {
                        // hostCustomer: "https://facepay-admin-service-luom7lul3q-as.a.run.app",
                        hostCustomer: "https://admin-dev.facepay.vn/adminservice",
                        host: "https://admin-dev.facepay.vn/cloud",
                        hostMedia: "https://admin-dev.facepay.vn/cloud/media",
                        hostWebSocket: "wss://admin-dev.facepay.vn/wss/notify/connect",
                    }
            }
        default:
            return {
                host: "https://dev.facepay.vn/cloud",
                hostMedia: "https://dev.facepay.vn/cloud/media",
                hostWebSocket: "wss://dev.facepay.vn/wss/notify/connect",
            }
    }
})()

export const refNotifyDevice = 'launcher_device'
export const refNotifyNewUser = 'Facepay/Admin/Users/Total'
export const refNotifySummaryFacepayService = 'Facepay/Admin/Users'
export const refNotifySummaryCloudServer = 'Facepay/Admin/Transactions'

export const apiMerchantLogo = (merchantId = "") => {
    return `${hostMedia}/download/merchantLogo?merchantId=${merchantId}&time=${moment().unix()}`;
};

export const apiSupplierLogo = (supplier = "") => {
    return `${hostMedia}/download/supplierLogo?supplier=${supplier}&time=${moment().unix()}`;
};

export const apiTransactionPhoto = (id = "") => {
    return `${hostMedia}/download/transactionPhoto?id=${id}&time=${moment().unix()}`;
};

export const apiTransactionVerifyVideo = (id = "") => {
    return `${hostMedia}/download/transactionVerifyVideo?id=${id}&time=${moment().unix()}`;
};

export const apiAdvertisementMerchantPhoto = (id = "") => {
    return `${hostMedia}/download/merchantAdvertisement?id=${id}&time=${moment().unix()}`;
};

export const apiGetBankCustomerAvatar = (id = "") => {
    return `${hostCustomer}/customer/transactionPhoto?id=${id}&time=${moment().unix()}`;
};

export const apiGetBankCustomerTransactionVideo = (id = "") => {
    return `${hostCustomer}/customer/transactionVideo?id=${id}&time=${moment().unix()}`;
};

export const apiGetLogoMerchantFacepay = (id = "") => {
    return `${hostCustomer}/customer/logo/${id}?time=${moment().unix()}`;
};
