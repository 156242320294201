import styled from 'styled-components'

const Bound = styled.div`
    display: flex;
    flex-grow: 1;
    padding: 16px 20px 22px 24px;
    flex-direction: column;
    position: relative;
    .wrap-empty {
        flex-grow: 1;
        height: 100%;
        display: flex;
        align-items: center;
        p{
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 13.5px;
            line-height: 18px;
            color: #B7B7B7;
        }
    }
    .wrap-detail{
        flex-grow: 1;
        display: grid;
        grid-template-rows: 120px 1fr;
        grid-gap: 40px;
        position: relative;
        .info{
            display: flex;
            justify-content: space-between;
            .info-left{
                display: flex;
                gap: 16px;
                .info-face{
                    position: relative;
                    .face-info{
                        width: 116px;
                        height: 116px;
                        border-radius: 50%;
                        object-fit: cover;
                    }
                    .bank-logo{
                        position: absolute;
                        bottom: 7px;
                        right: 7px;
                        border-radius: 50%;
                        width: 32px;
                        height: 32px;
                        svg{
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
                .info-item{
                    display: flex;
                    flex-direction: column;
                    gap: 12px;
                    .info-name{
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 33px;
                        letter-spacing: -0.01em;
                        color: #313131;
                    }
                    .info-type{
                        display: flex;
                        align-items: center;
                        gap: 6px;
                        .info-status{
                            display: flex;
                            align-items: center;
                            gap: 4px;
                            background: #EFF9FF;
                            border-radius: 20px;
                            color: #313131;
                            padding: 3px 7px 3px 3px;
                        }
                        .info-locked{
                            display: flex;
                            align-items: center;
                            gap: 4px;
                            background: #FFEEEE;
                            border-radius: 20px;
                            color: #313131;
                            padding: 3px 7px 3px 3px;
                        }
                        .icon{
                            display: flex;
                            align-items: center;
                        }
                        .text{
                            font-weight: 600;
                            font-size: 13.5px;
                            line-height: 18px;
                            letter-spacing: -0.01em;
                        }
                        .info-id{
                            font-weight: 400;
                            font-size: 13.5px;
                            line-height: 18px;
                            letter-spacing: -0.01em;
                            color: #313131;
                        }
                    }
                    .locked-error{
                        font-weight: 400;
                        font-size: 13.5px;
                        line-height: 18px;
                        letter-spacing: -0.01em;
                        color: #F45C5C;
                    }
                }
            }
            .btn-locked{
                width: 130px;
                height: 34px;
                background: #F45C5C;
                border-radius: 4px;
                font-weight: 600;
                font-size: 13.5px;
                line-height: 18px;
                letter-spacing: -0.01em;
                color: #FFFFFF;
                padding: 0;
            }
            .btn-open{
                width: 94px;
                height: 34px;
            }
        }
        .more_info{
            display: flex;
            gap: 65px;
            align-items: center;
            .more_info-item{
                display: flex;
                gap: 9px;
                .more_info-text{
                    .value{
                        font-weight: 600;
                        font-size: 13.5px;
                        line-height: 18px;
                        letter-spacing: -0.01em;
                        color: #313131;
                        display: flex;
                        align-items: center;
                        gap: 4px;
                        svg{
                            width: 12px;
                            height: 12px;
                        }

                    }
                    .text{
                        font-weight: 400;
                        font-size: 11px;
                        line-height: 15px;
                        letter-spacing: -0.02em;
                        color: #777777;
                    }
                }
            }
        }
    }
`

export {
    Bound
}