const checkTimeTransactionComplete = (time) => {
    // const hours = Math.floor(time / 1000 / 60 / 60)
    const minutes = Math.floor((time / 1000 / 60) % 60) % 60
    const second = Math.floor((time / 1000) % 60)

    return pad(minutes.toString() , 2) + ":" + pad(second.toString() , 2)
}

const pad = (numberStr, number) => {
    let padded = numberStr
    while (padded.length < number) padded = `0${padded}`
    return padded
}

export default checkTimeTransactionComplete