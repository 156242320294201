import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

// css
import { OverlayFullScreen } from "../../../TransactionHistory/TransHistoryItem/PopupShowMedia/index.styles";
import { WrapWarningApproved } from './index.styles'

// images
import IcRefreshWhite from "../../../../../../../assets/images/IcRefreshWhite";

// component
import Button from "../../../../../../Control/Button";

// reudx
import { getDetailsCensorship } from "../../../../../../../redux/censorship/detailCensorship/action";


const PopupWarningApproved = () => {

  const { censorshipId } = useParams();
  const dispatch = useDispatch()

  const handleSubmit = () => {
    dispatch(getDetailsCensorship({ uid: censorshipId }))
  }

  return (
    <OverlayFullScreen>
      <WrapWarningApproved>
        <div className="warning-title">
          Có sự thay đổi trong hồ sơ trùng khớp
        </div>
        <div className="warning-content">
          Bạn vui lòng làm mới dữ liệu và kiểm tra lại thông tin
        </div>
        <Button
          iconBtn={<IcRefreshWhite />}
          type="submit"
          text="Làm mới dữ liệu"
          className="btn-warning"
          onClick={handleSubmit}
        />
      </WrapWarningApproved>
    </OverlayFullScreen>
  );
};

export default PopupWarningApproved;
