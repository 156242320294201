import styled from "styled-components";

const WrapTransactionItem = styled.div`  
    display: grid;
    grid-template-columns: minmax(95px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(175px, 1fr) minmax(60px, 1fr) minmax(60px, 1fr) minmax(90px, 1fr) minmax(130px, 1fr) minmax(95px, 1fr) 60px;
    grid-gap: 10px;
    height: 54px;
    align-items: center;
    background: #FFFFFF;
    box-shadow: inset 0px -0.55px 0px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    margin-bottom: 3px;
    cursor: pointer;
    
    .wrap-item{
        display: flex;
        align-items: center;       
        .icon{
            margin-right: 5px;
        }
        .avatar{
            margin-right: 9px;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            object-fit: cover;
        }       
        &.facepayTx{
            padding-left: 16px;
            font-weight: 600;
        }
        &.refund{
            justify-content: flex-end;
            padding-right: 20px;
        }
        .icon-arrow{
            padding-left: 11px;
        }
    }
    .wrap-icon{        
        .icon {
            width: 30px;
            height: 30px;
            padding-left: 10px;
            svg{
                width: 30px;
                height: 30px;
            }
            img{
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }
    }
    .item-text{
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 13.5px;
        line-height: 18px;
        letter-spacing: -0.01em;
        color: #313131;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &.bold{
            font-weight: 600;
        }
    }

`

export {
    WrapTransactionItem
}