import styled from 'styled-components'

const WrapHeaderContent = styled.div`
    width: 100%;
    height: 112px;
    background: #FFFFFF;
    box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.15);
    .wrap-header-content{
        min-width: calc(1280px - 100px);
        box-sizing: border-box;
        height: 100%;
        display: flex;
        justify-content: space-between;
        padding: 24px 30px;
        align-items: center;
        .bank_info{
            display: flex;
            align-items: center;
            .logo{
                width: 60px;
                height: 60px;
            }
            .name_bank{
                color: #313131;
                font-weight: 600;
                font-size: 24px;
                font-family: "Open Sans";
                line-height: 33px;
                padding-left: 15px;
                letter-spacing: -0.01em;
            }
        }
        .total{
            color: #313131;
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 27px;
            letter-spacing: -0.01em;
            
            .list-customer-total, .list-censorship, .list-trans-total{
                display: grid;
                grid-column-gap: 44px;
                div{
                    display: flex;
                    flex-direction: column;
                }
                .num{
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 33px;
                    color: #313131;
                    padding-bottom: 4px;
                    &.wait{
                        color: #33ADFF;
                    }
                    &.over{
                        color: #F45C5C;
                    }
                    &.blue{
                        color: #33ADFF;
                    }
                }
                .text{
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 11px;
                    line-height: 15px;
                    color: #777777;
                }
                .success{
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 11px;
                    line-height: 15px;
                    letter-spacing: -0.02em;
                    color: #33ADFF;
                }
                .fail{
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 11px;
                    line-height: 15px;
                    letter-spacing: -0.02em;
                    color: #F45C5C;
                }
            }
            .list-censorship{
                grid-template-columns: repeat(3, auto);
            }
            .list-customer-total{
                grid-template-columns: repeat(5, auto);
            }
            .list-trans-total{
                grid-template-columns: repeat(4, auto);
            }
        }
    }
`;

export {
    WrapHeaderContent
}