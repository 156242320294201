
// lỗi nạp tiền vào Ví facepay update 21/11/2022

export const dataErrorsDeposit = {
    ['08']: {text: "Giao dịch không thành công do lỗi hệ thống"},
    ['2701010']: {text: "Giao dịch không thành công do lỗi hệ thống"},
    ['11']: {text: "Giao dịch không thành công do lỗi hệ thống"},
    ['1104']: {text: "Tài khoản Facepay chưa được xác thực"},
    ['1110']: {text: "Tài khoản Facepay chưa được định danh"},
    ['1118']: {text: "Giao dịch không thành công do lỗi hệ thống"},
    ['600']: {text: "Tài khoản tạm thời bị khóa do nghi ngờ các hành vi phát sinh trong hệ thống"},
    ['601']: {text: "Tài khoản tạm thời bị khóa do user yêu cầu"},
    ['1112']: {text: "Tài khoản chưa kích hoạt ví"},
    ['1134']: {text: "Số tiền giao dịch nằm ngoài phạm vi cho phép"},

}