import React, { useState, useEffect } from 'react'

// styled
import { WrapPopupDeviceDetail } from './index.styles'
import { OverlayFullScreen } from '../../TransactionHistory/TransHistoryItem/PopupShowMedia/index.styles'

// image
import IcCloseWhite from '../../../../../../assets/images/IcCloseWhite'
import { convertDateTime } from '../../../../../../helper/convertDateTime'
import IcDeviceOutlineBlue from '../../../../../../assets/images/IcDeviceOutlineBlue'
import DevicesInfo from '../../../../../../assets/images/img_devices_info.png'
import LogoGS25 from '../../../../../../assets/images/GS25.png'
import IcWifi from '../../../../../../assets/images/IcWifi'
import IcEthBlue from '../../../../../../assets/images/IcEthBlue'

// data
import { dataFeatureDevice } from '../../../../../../data/dataDeviceFeature'
import { DEVICE_APP_INFO, DEVICE_HARDWARE_INFO } from '../../../../../../constant/keys'
import { DEV_SV, QC_SV, PROD_SV, SBX_SV } from '../../../../../../constant/environmentTypes'

// component
import DeviceAppInfo from './DeviceAppInfo'
import DeviceHareWare from './DeviceHardWare'
import TabListMenu from '../../../../../Control/TabListMenu'
import handleCallApiLuancherAction from '../HandleCallApiLuancherAction'
import IcMerchantLogoDefault from '../../../../../../assets/images/IcMerchantLogoDefault'

const dataDeviceInfo = [
    {
        title: 'Thông tin app',
        key: DEVICE_APP_INFO,
        component: {
            type: 'DEVICE_APP_INFO',
            comp: <DeviceAppInfo />
        },
        envServerValid: [
            DEV_SV, QC_SV, PROD_SV, SBX_SV
        ]
    },
    {
        title: 'Thông tin phần cứng',
        key: DEVICE_HARDWARE_INFO,
        component: {
            type: 'DEVICE_HARDWARE_INFO',
            comp: <DeviceHareWare />
        },
        envServerValid: [
            DEV_SV, QC_SV, PROD_SV, SBX_SV
        ]
    },
]

const PopupDeviceDetail = ({ onClosePopup, data, objStatus, imageUrl }) => {

    const [component, setComponent] = useState(null)

    const { vnText, color } = objStatus
    const [isErrImg, setIsErrImg] = useState(false)
    
    const handleChangeTab = (component) => {
        setComponent(component.comp)
    }

        
    useEffect(() => {
        setIsErrImg(false)
      }, [imageUrl])

    const renderContentDeviceFeature = () => {
        return (
            dataFeatureDevice.map((item) => {
                return item.isShowPopupDeviceDetail ? (
                    <div className='device-feature-detail' key={item.id} onClick={handleCallApiLuancherAction(item.key, [data.serialNumber])}>
                        <div className='feature-icon'>
                            {item.icon}
                        </div>
                        <div className='feature-value'>
                            {item.text}
                        </div>
                    </div>
                ): ""
            })
        )
    }
    
    return (
        <OverlayFullScreen>
            <WrapPopupDeviceDetail colorByStatus={color}>
                <div className='popup-detail'>
                    <IcCloseWhite className='icon-close' onClick={onClosePopup} />
                    <div className='detail-title'>
                        <div className='title-left'>
                            <IcDeviceOutlineBlue />
                            {data.appInfo?.linkCode || "-"}
                        </div>
                        <div className='title-right'>
                            <div className="normal">{data.time ? convertDateTime(data.time, "HH:mm DD/MM/YYYY") : "-"}</div>
                            <div className="icon-status"></div>
                            <div className="color-status">{vnText || "-"}</div>
                        </div>
                    </div>
                    <div className='detail-content'>
                        <div className="detail-content-top">
                            <div className='detail-device'>
                                <div className='detail-device-left'>
                                    <div className="image">
                                        <img src={DevicesInfo} alt="device"/>
                                    </div>
                                    <div className="device-detail">
                                        <span className='device-code'>
                                            {data.appInfo?.name || "-"}
                                            <div className="device-icon">
                                            {
                                                data.ipAddress?.wlan0 ? <IcWifi /> : 
                                                data.ipAddress?.eth0 ? <IcEthBlue /> : 
                                                data.ipAddress?.wlan0 && data.ipAddress?.eth0 ? (
                                                    <>
                                                        <IcWifi /> <IcEthBlue />
                                                    </>
                                                ): ""
                                            }
                                        </div>
                                        </span>
                                        <span className="device-name" title={data.appInfo?.shopName}>{data.appInfo?.shopName || "-"}</span>
                                    </div>
                                </div>
                                <div className='detail-device-right'>
                                {!isErrImg ? 
                                        <img 
                                            src={imageUrl} 
                                            className='logo' 
                                            onError={() => setIsErrImg(true)
                                        }/> : <IcMerchantLogoDefault />
                                }
                                    <div className="device-detail">
                                        <span className='merchant'>Merchant</span>
                                        <span className="merchant-name">GS25</span>
                                    </div>
                                </div>
                            </div>
                            <div className='device-feature'>
                                {renderContentDeviceFeature()}
                            </div>
                        </div>
                        <div className='menu'>
                            <TabListMenu  
                                data={dataDeviceInfo} 
                                componentSelected={handleChangeTab}
                            /> 
                            {component}
                        </div> 
                    </div>
                </div>
            </WrapPopupDeviceDetail>
        </OverlayFullScreen>
    )
}

export default PopupDeviceDetail
